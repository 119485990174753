import React, { Fragment, useState } from "react";
import { take } from "rxjs";
import "./../tabs/estilo.css";
import "bootstrap";
import ContentTabs from "../tabs/ContentTabs";
import { IStatementResponse, IStatementExpiredResponse } from "../../shared/services/Extract/ExtractService.interface";
import ContentTabsExpired from "../tabs/ContentTabsExpired";
import { useAnalyticsService } from "../../shared/hooks/useAnalyticsService";
import { useExtractService } from "../../shared/hooks/useExtractService";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const FilterSection = (viewModel: IFilterSectionViewModel) => {
  const extractService = useExtractService();
  const analyticsService = useAnalyticsService();
  const [isLoading, setIsLoading] = useState(false);
  const [statementsPointsExpirationResponse, setStatementsPointsExpiration] = useState<IStatementExpiredResponse>();
  
  const pageName = 'IUPPTelaExtrato';

  function getStatementsPointsExpiration() {
    setIsLoading(true);
    const statementsResponseSubscription = extractService.getStatementsPointsExpiration()
    .pipe(
      take(1)
    ).subscribe({next: (apiResponse) => {
      setStatementsPointsExpiration(apiResponse);
      setIsLoading(false);
    }, error: () => {
      setIsLoading(false);
      setStatementsPointsExpiration({
        hasFailed: true
      } as IStatementExpiredResponse)
    }});

    return () => {
      statementsResponseSubscription.unsubscribe();
    };
  }

  function clickTabs(event: any) {
    const eventLabel = analyticsService.captalizeAndRemoveSpaces(event.target.innerHTML);
    analyticsService.trackAction(pageName, eventLabel);
    eventLabel === "AExpirar" && statementsPointsExpirationResponse === undefined && getStatementsPointsExpiration();
  }

  return (
    <Fragment>
      <ul className="mb-3 nav nav-tabs mx-3" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="detailed"
            data-bs-toggle="tab"
            data-bs-target="#detailed-pane"
            type="button"
            role="tab"
            aria-controls="detailed-pane"
            aria-selected="true"
            onClick={clickTabs}
          >
            Detalhado
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="expired"
            data-bs-toggle="tab"
            data-bs-target="#expired-pane"
            type="button"
            role="tab"
            aria-controls="expired-pane"
            aria-selected="false"
            onClick={clickTabs}
          >
            A expirar
          </button>
        </li>
      </ul>

      <div className="tab-content list-tabs" id="tab-home">
        <div
          className="tab-pane fade show active"
          id="detailed-pane"
          role="tabpanel"
          aria-labelledby="detailed"
        >
          {viewModel.statements.statements.length > 0
           ? <ContentTabs statements={viewModel.statements.statements} />
           : 
            <div data-testid='btn-track' className='m-app content-error txt-center'>
              <div aria-hidden="true" className={`font-size-48 icon-itaufonts_ajuda`}>
                <h3 className='title font-size-24 mt-3'>
                  você ainda não tem pontos
                </h3>
                <p className='font-size-16 color-black mt-1 lh-25'>
                  Ganhe pontos através de cartões de crédito elegíveis, cashback no Itaú Shop, clube de vinhos, lojas parceiras e troque por desconto na fatura, produtos Itaú, transferência para parceiros aéreos e muito mais!
                </p>
              </div>
            </div>
          }
        </div>
        <div
          className="tab-pane fade"
          id="expired-pane"
          role="tabpanel"
          aria-labelledby="expired"
        >
          { isLoading && <span className="loading"></span> }
          { statementsPointsExpirationResponse !== undefined && !statementsPointsExpirationResponse.hasFailed && !isLoading
            ? statementsPointsExpirationResponse.points_to_expire.length > 0 
              ? <ContentTabsExpired statementsExpiredProp={statementsPointsExpirationResponse.points_to_expire} />
              : <ErrorMessage title="você não tem pontos a expirar nos próximos 24 meses" subtitle="Fique tranquilo! Iremos te avisar por aqui quando algum ponto estiver próximo a expirar." icons="icon-itaufonts_sorriso" />
            : !isLoading && <div className="mb-5"><ErrorMessage retry={getStatementsPointsExpiration} title="algo deu errado :(" subtitle="Não foi possível carregar as informações do seu extrato" icons="icon-itaufonts_exclamacao" /></div>
          }
        </div>
      </div>
    </Fragment>
  );
};

interface IFilterSectionViewModel {
  filterDate: string;
  filterType: string;
  filterBrand: string;
  filterPartener:string;
  statements: IStatementResponse;
}

export default FilterSection;