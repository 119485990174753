import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { IStatementDetailItem } from "../../components/tabs/ContentTabs.interface";
import { useNativeCommunicationService } from "../../shared/hooks/useNativeCommunicationService";
import moment from "moment";

const COLOR_GRAY = "color-ui-new-1";
const COLOR_GREEN = "color-money";
const IPHONE_PRA_SEMPRE = "Iphone pra sempre";

const ExtractDetails = () => {
  const nativeCommunicationService = useNativeCommunicationService();
  const [grayOrGreen, setGrayOrGreen] = useState(COLOR_GRAY);
  const [mostrarIrParaPedido, setMostrarIrParaPedido] = useState(false);
  const categoriasMostrarIrParaPedido = ['SHOP', 'PLANE', 'CELULAR'];
  const { state } = useLocation();
  const details = state as IStatementDetailItem;
  const categoriaPlaneOuPontos = details.categoria === "PLANE" || details.categoria === "PONTOS";
  const categoriaFatuOuShopAndValuesPointsNegative = details.valorReal && (details.categoria === "FATU" || details.categoria === "SHOP") && details.valueInPoints < 0;
  
  const dataCadastro = details.dataCadastro;
  const dataCadastroFormatada = moment(dataCadastro).locale('pt-br').format("D [de] MMMM [de] YYYY [às] HH[h]mm");

  const dataExpiracao = details.dataExpiracao;
  const dataExpiracaoFormatada = moment(dataExpiracao).locale('pt-br').format("DD/MM/YYYY");

  useEffect(() => {
    if(details.categoria !== undefined) {
      console.log(details);
      
      if(categoriasMostrarIrParaPedido.includes(details.categoria)) {
        setMostrarIrParaPedido(true);
      }
      if(IPHONE_PRA_SEMPRE.includes(details.vendorName)) {
        setMostrarIrParaPedido(false);
      }
    }
    details.valueInPoints > 0 && setGrayOrGreen(COLOR_GREEN);
  }, []);

  const IrParaPedido = () => nativeCommunicationService.itauShop("itau_shop?route=/me/orders");
  const IrCentralDeAJuda = () => nativeCommunicationService.pointsCashbackDeeplink("/central-de-ajuda");

  return (
    <div className="m-app">
      <Row>
        <Col xs={12} className="mt-4 txt-left">
          <div className="col-2 icon-with-rounded-background w-64 gray-background"><i className={"icon w-48 " + grayOrGreen + " " + details.icons}></i></div>
        </Col>
        <Col xs={12} className="mt-3 txt-left">
          <h2 className="font-size-24 mb-0">
            {details.product}
          </h2>
        </Col>
        <Col xs={12} className="mt-0 txt-left">
          <p className="font-size-16 color-no-points">{dataCadastroFormatada}</p>
        </Col>
        <Col xs={12} className="mt-5 txt-left">
          <Row>
            <Col xs={12} className="row">
              <Col xs={6} className="txt-left">
                <p className="mb-0">valor total em pontos</p>
              </Col>
              <Col xs={6} className="txt-right">
                <p className={"mb-0 " + grayOrGreen}>
                  { details.valueInPoints > 0 ? "+ " + details.valueInPoints.toLocaleString("pt-BR") : details.valueInPoints.toLocaleString("pt-BR") } pts
                </p>
              </Col>
            </Col>
            <div className="border-bottom-ddd my-3 line-c" />

            { categoriaFatuOuShopAndValuesPointsNegative && 
              <Col xs={12} className="row">
                <Col xs={6} className="txt-left">
                  <p className="mb-0">valor em reais</p>
                </Col>
                <Col xs={6} className="txt-right">
                  <p className={"mb-0 " + grayOrGreen}>
                    {details.valorReal?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    })}
                  </p>
                </Col>
                <div className="border-bottom-ddd my-3 line-c" />
              </Col>
            }
            
            { details.categoria === "DOACAO" &&
              <Col xs={12} className="row">
                <Col xs={6} className="mt-2 txt-left">
                  <p className="mb-0">doado para</p>
                </Col>
                <Col xs={6} className="mt-2 txt-right">
                  <p className="mb-0 color-ui-new-1">{ details.vendorName }</p>
                </Col>
                <div className="border-bottom-ddd my-3 line-c" />
              </Col>
            }

            { details.categoria === "TRANSF" &&
              <Col xs={12} className="row">
                <Col xs={6} className="mt-2 txt-left">
                  <p className="mb-0">transferido para</p>
                </Col>
                <Col xs={6} className="mt-2 txt-right">
                  <p className="mb-0 color-ui-new-1">{ details.vendorName }</p>
                </Col>
                <div className="border-bottom-ddd my-3 line-c" />
              </Col>
            }

            { details.categoria === "BENEFICIOS" &&
              <Col xs={12} className="row">
                <Col xs={6} className="mt-2 txt-left">
                  <p className="mb-0">cartão</p>
                </Col>
                <Col xs={6} className="mt-2 txt-right">
                  <p className="mb-0 color-ui-new-1">{ details.vendorName }</p>
                </Col>
                <div className="border-bottom-ddd my-3 line-c" />
              </Col>
            }

            { categoriaPlaneOuPontos &&
              <Col xs={12} className="row">
                <Col xs={6} className="mt-2 txt-left">
                  <p className="mb-0">categoria</p>
                </Col>
                <Col xs={6} className="mt-2 txt-right">
                  <p className="mb-0 color-ui-new-1">{ details.vendorName }</p>
                </Col>
                <div className="border-bottom-ddd my-3 line-c" />
              </Col>
            }

            { details.categoria === "SHOP" &&
              <Col xs={12} className="row">
                <Col xs={6} className="mt-2 txt-left">
                  <p className="mb-0">vendido por</p>
                </Col>
                <Col xs={6} className="mt-2 txt-right">
                  <p className="mb-0 color-ui-new-1">{ details.vendorName }</p>
                </Col>
                <div className="border-bottom-ddd my-3 line-a" /> 
              </Col>
            }

            { details.dataExpiracao &&
              <Col xs={12} className="row">
                <Col xs={6} className="mt-2 txt-left">
                  <p className="mb-0">irão expirar em</p>
                </Col>
                <Col xs={6} className="mt-2 txt-right">
                  <p className="mb-0 color-ui-new-1">{ dataExpiracaoFormatada }</p>
                </Col>
                <div className="border-bottom-ddd my-3 line-a" /> 
              </Col>
            }

            <Col xs={12} className="row">
              <Col xs={6} className="mt-2 txt-left">
                <p className="mb-0">número do pedido</p>
              </Col>
              <Col xs={6} className="mt-2 txt-right">
              <p className="mb-0 color-ui-new-1">{ details.idPedido }</p>
              </Col>
            </Col>
          </Row>
        </Col>
        <div className='mt-5 mb-3'>
          { mostrarIrParaPedido && <button onClick={() => {IrParaPedido()}} className='clean-filters'>ir para o pedido</button> }
          <button onClick={() => {IrCentralDeAJuda()}} className='submit-filters mt-2'>central de ajuda</button>
        </div>
      </Row>
    </div>
  )
}

export default ExtractDetails
