import React, { Fragment, useState } from 'react'
import { useAnalyticsService } from '../../shared/hooks/useAnalyticsService';
import useEffectOnlyOnce from '../../shared/hooks/useEffectOnlyOnce';
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const [isTagged, setIsTagged] = useState(false);
  const analyticsService = useAnalyticsService();
  const pageName = 'IUPPTelaExtrato';
  const navigate = useNavigate();

  useEffectOnlyOnce(
    () => {
      analyticsService.trackState(pageName);
    },
    [isTagged],
    (dependencies: boolean[]) => dependencies[0] === false
  );

  function tryAgainButtonTapped(event: any) {
    const eventLabel = analyticsService.captalizeAndRemoveSpaces(event.target.innerHTML);

    analyticsService.trackAction(pageName, eventLabel);

    navigate('/', { replace: true });
  }

  return (
    <Fragment>
        <div className='m-app error-div txt-left'>
          <div className='icon-custom icon_warning_purple' />
          <p className='font-size-24 color-error mt-4'>
            algo deu errado :(
          </p>
          <p className='font-size-16 color-black mt-4'>
            Não foi possível carregar as <br />informações.
          </p>
          <button className='btn-reload mt-2' onClick={tryAgainButtonTapped}>
            tentar novamente
          </button>
        </div>
    </Fragment>
  )
}

export default NotFound;
