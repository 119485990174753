import React, { Fragment } from "react";
import Carousel from '../../components/carousel/Carousel';
import CardComponet from '../../components/card/CardComponet';
import ModalComponent from '../../components/modal/ModalComponent';
import { MockBannerNotScored } from "../../mock/MockBannerNotScored"
import { ModalComponentData } from "../../mock/ModalComponentData";
import { HomeCard } from "../../components/HomeCard/HomeCard";

const NotScored = () => {
  return (
    <Fragment>
      <Carousel data={MockBannerNotScored} />
      <CardComponet />
      <HomeCard />
      <ModalComponent viewModel={ModalComponentData} />
    </Fragment>
  );
};

export default NotScored;
