import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import LogoMarketPlace from "../../Styles/img/icons/icon-marketplace.png"

const MarketPlaceTravelTickets = () => {
  // TODO: Carregar tagueamento

  const ref =useRef(null)

  function adobeTrackStateTravel() {
    const analyticsObj = {
      "page": {
          "name": "MarketPlaceTravelTickets",
          "statusLogin": "Logado"
      },
      "visitor": {
          // "segmentoConta": native.getMemoryItem('segmento')
      },
      "rule": "pageLoad"
    };
    console.log(analyticsObj)
  }

  useEffect(() => {
    setTimeout(() => {
      let foco = document.querySelector("#voltar") as HTMLElement
      foco.focus()
    },300)
    adobeTrackStateTravel()
  }, []);

  function adobeTrackAction() {
    const analyticsObjRequest = {
        "eventCategory": "Clique em ir para pedido",
        "eventAction": "Exemplo",
        "eventLabel": "BTN:SDK-WebView:Exemplo"
    }
    console.log(analyticsObjRequest);
  }

  function adobeTrackActionContest() {
    const analyticsObjContest = {
        "eventCategory": "Clique em Contestar Extato",
        "eventAction": "Exemplo",
        "eventLabel": "BTN:SDK-WebView:Exemplo"
    }
    console.log(analyticsObjContest);
  }

  let navigate = useNavigate();

  const handleContestExtract = () => {
    navigate('/contest-extract')
  }

  const handleToHomePointsExpire = () => {
    navigate('/')
  }

  return (
    <div className="m-app">
      <Row>
      <Col xs={1} className="my-5 header-internal">
        <div tabIndex={0} ref={ref} id="ExtractVoltar" role="button" aria-label="Voltar" className="f-none">
          <div className="col-4 txt-left mr-5 f-none" onClick={handleToHomePointsExpire}>
            <i className="icon-itaufonts_seta color-ui-new-1 font-size-24"></i>
          </div>
        </div>
        </Col>
        <Col xs={12} className="mt-4 txt-left">
          <img tabIndex={0} alt="Logo Iupp" src={LogoMarketPlace} />
        </Col>
        <Col xs={12} className="mt-3 txt-left">
          <h1 className="font-size-24 mb-0">
            itaú marketplace
          </h1>
        </Col>
        <Col xs={12} className="mt-0 txt-left">
          <p tabIndex={0} className="font-size-16 color-no-points">11 de fevereiro de 2022 às 13h10</p>
        </Col>
        <Col xs={12} className="mt-5 txt-left">
          <Row>
            <Col role="text" tabIndex={0} xs={12} className="row">
              <Col xs={6} className="txt-left">
                <p className="mb-0">valor total</p>
              </Col>
              <Col xs={6} className="txt-right">
                <p className="mb-0">1.550 pts</p>
              </Col>
            </Col>
            <div className="border-bottom-ddd my-3 line-a" />
            <Col role="text" tabIndex={0} xs={12} className="row">
              <Col xs={6} className="mt-2 txt-left">
                <p className="mb-0">pontos creditados</p>
              </Col>
              <Col xs={6} className="mt-2 txt-right">
                <p className="color-money bold mb-0">+ 550 pts</p>
              </Col>
            </Col>
            <div className="border-bottom-ddd my-3 line-b" />
            <Col role="text" tabIndex={0} xs={12} className="row">
              <Col xs={6} className="mt-2 txt-left">
                <p className="mb-0">irão expirar em</p>
              </Col>
              <Col xs={6} className="mt-2 txt-right">
                <p className="mb-0">31/06/22</p>
              </Col>
            </Col>
            <div className="border-bottom-ddd my-3 line-c" />
            <Col role="text" tabIndex={0} xs={12} className="row">
              <Col xs={6} className="mt-2 txt-left">
                <p className="mb-0">vendido por</p>
              </Col>
              <Col xs={6} className="mt-2 txt-right">
                <p className="mb-0">Azul</p>
              </Col>
            </Col>
            <div className="border-bottom-ddd my-3 line-d" />
            <Col role="text" tabIndex={0} xs={12} className="row">
              <Col xs={6} className="mt-2 txt-left">
                <p className="mb-0">categoria</p>
              </Col>
              <Col xs={6} className="mt-2 txt-right">
                <p className="mb-0">passagem aérea</p>
              </Col>
            </Col>
          </Row>
        </Col>
        <div className='mt-5 mb-3'>
          <button className='clean-filters' onClick={adobeTrackAction}>
            ir para o pedido
          </button>
          <button 
            className='submit-filters mt-2' 
            onClick={() => {
              handleContestExtract();
              adobeTrackActionContest();
            }}>
            contestar extrato
          </button>
        </div>
      </Row>
    </div>
  )
}

export default MarketPlaceTravelTickets
