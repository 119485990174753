import React, { FC } from "react";
import Contextualizer from "../Contextualizer";
import ProvidedServices from "../ProvidedServices";
import ExtractServiceImpl from "./ExtractService";
import IExtractService from "./ExtractService.interface";

const ExtractServiceContext = Contextualizer.createContext(ProvidedServices.ExtractService);

const ExtractService: FC<any> = ({children}: any) => {
  const extractService: IExtractService = new ExtractServiceImpl();

  return (
    <>
      <ExtractServiceContext.Provider value={extractService}>
        {children}
      </ExtractServiceContext.Provider>
    </>
  )
}

export default ExtractService;
