import React from "react";
import Card from "./Card";
import { MockCard } from "../../mock/MockCard";

const CardComponet = () => {
  return (
    <>
    <div className="border-header-top"></div>
    </>
  );
};

export default CardComponet;
