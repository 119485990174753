import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import LogoInvoice from "../../Styles/img/icons/icon-invoice.png"

const Invoice = () => {
  // TODO: Carregar tagueamento

  function adobeTrackStateInvoice() {
    const analyticsObj = {
      "page": {
          "name": "Invoice",
          "statusLogin": "Logado"
      },
      "visitor": {
          // "segmentoConta": native.getMemoryItem('segmento')
      },
      "rule": "pageLoad"
    };
    console.log(analyticsObj)
  }

  useEffect(() => {
    adobeTrackStateInvoice()
  }, []);

  let navigate = useNavigate();

  const handleToHomeInvoice = () => {
    navigate('/')
  }

  return (
    <div className="m-app">
      <Row>
        <Col xs={12} className="mt-5 mb-4 header-internal">
          <div className="col-1 txt-left">
            <i className="icon-itaufonts_seta color-ui-new-1 font-size-24" onClick={handleToHomeInvoice} />
          </div>
        </Col>
        <Col xs={12} className="mt-4 txt-left">
          <img src={LogoInvoice} alt="" />
        </Col>
        <Col xs={12} className="mt-1 txt-left">
          <h1>Fatura Itaucard Uniclass 0390</h1>
        </Col>
        <Col xs={12} className="mt-1 txt-left">
          <h2>12 de outubro 2021 às 14h02</h2>
        </Col>
        <Col xs={12} className="mt-5 txt-left">
          <Row>
            <Col xs={6} className="txt-left">
              <p className="mb-1">valor total</p>
            </Col>
            <Col xs={6} className="txt-right">
              <p className="mb-1">R$ 8.550,59</p>
            </Col>
            <div className="border-bottom-ddd my-3 line-a" />
            <Col xs={6} className="mt-2 txt-left">
              <p className="mb-1">pontos creditados</p>
            </Col>
            <Col xs={6} className="mt-2 txt-right">
              <p className="mb-1">+ 1120 pts</p>
            </Col>
            <div className="border-bottom-ddd my-3 line-b" />
            <Col xs={6} className="mt-2 txt-left">
              <p className="mb-1">será creditado em</p>
            </Col>
            <Col xs={6} className="mt-2 txt-right">
              <p className="mb-1">até 40 dias</p>
            </Col>
            <div className="border-bottom-ddd my-3 line-c" />
            <Col xs={6} className="mt-2 txt-left d-flex justify-items-left">
              <p className="mb-1">irão expirar em</p>
              <i className="icon-custom icon_info ms-1 mt-1" />
            </Col>
            <Col xs={6} className="mt-2 txt-right">
              <p className="mb-1">31/06/22</p>
            </Col>
            <div className="border-bottom-ddd my-3 line-d" />
            <Col xs={6} className="mt-2 txt-left">
              <p className="mb-1">categoria</p>
            </Col>
            <Col xs={6} className="mt-2 txt-right">
              <p className="mb-1">cartão de crédito</p>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <button className='clean-filters mt-5'>
            ir para a fatura
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default Invoice
