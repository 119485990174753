import React, { FC } from "react";
import { useNativeCommunicationService } from "../../hooks/useNativeCommunicationService";
import Contextualizer from "../Contextualizer";
import ProvidedServices from "../ProvidedServices";
import SecurityServiceImpl from "./SecurityService";
import ISecurityService from "./SecurityService.interface";

const SecurityServiceContext = Contextualizer.createContext(ProvidedServices.SecurityService);

const SecurityService: FC<any> = ({children}: any) => {
  const SecurityService: ISecurityService = new SecurityServiceImpl(
    useNativeCommunicationService()
  );

  return (
    <>
      <SecurityServiceContext.Provider value={SecurityService}>
        {children}
      </SecurityServiceContext.Provider>
    </>
  )
}

export default SecurityService;
