import React, { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import "moment/locale/pt-br";
import Modal from "react-bootstrap/Modal";
import { ModalComponentData } from "../../mock/ModalComponentData";
import { useNavigate } from "react-router-dom";
import { IContentTabsProps, IStatementDateItem, IStatementDetailItem } from "./ContentTabs.interface";

const ContentTabs = (props: IContentTabsProps) => {
  const labelTodos = "todos";
  const labelCreditados = "creditados";
  const labelResgatados = "resgatados";
  const labelExpirados = "expirados";
  const labelIconeProgramaPontos = "icon-itaufonts_full_programa_de_pontos";

  const [show, setShow] = useState(false);
  const [filterDate, setFilterDate] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterOrder, setFilterOrder] = useState("");
  const [buttonActiveDate, setButtonActiveDate] = useState(false);
  const [buttonActiveType, setButtonActiveType] = useState(false);
  const [buttonActiveOrder, setButtonActiveOrder] = useState(false);
  const [buttonFilter, setButtonFilterActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterSelect, setIsFilterSelect] = useState(false);
  const [clearAllDisabled, setClearAllDisabled] = useState(true); 
  const [countSelectFilterTags, setCountSelectFilterTags] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [showCountTags, setShowCountTags] = useState(false);
  
  const [semResultado, setSemResultado] = useState(false);

  const [targetNumberFilterDate, setTargetNumberFilterDate] = useState(0);
  const [typeFilterActiveName, setTypeFilterActiveName] = useState("");
  const [orderFilterActiveName, setOrderFilterActiveName] = useState("");

  const [clearButtonDate, setClearButtonDate] = useState(false);
  const [clearButtonType, setClearButtonType] = useState(false);
  const [clearButtonOrder, setClearButtonOrder] = useState(false);
  
  const viewModel = {
    modalComponent: ModalComponentData
  }
  // TODO: handleNavegateContent e handleNavegateContentKeyEnter são as rotas pra internas

  const labelPointsExpired = "Expiração dos Pontos";
  const labelIconItauFonts ="icon-itaufonts_";
  const labelIconItauFontsFull = "icon-itaufonts_full_";

  const retornaIconeCategoria = (categoria?: string): string => {
    switch(categoria) {
      case "DOACAO":
        return `${labelIconItauFontsFull}vida`;
      case "FATU":
        return `${labelIconItauFonts}cobranca_ativa`;
      case "TRANSF":
        return `${labelIconItauFonts}tranferencias`;
      case "PLANE":
        return `${labelIconItauFontsFull}viagem`;
      case "SHOP":
        return `${labelIconItauFontsFull}compras`;
      case "RESSHOP":
        return `${labelIconItauFontsFull}compras`;
      case "FOOD":
        return `${labelIconItauFontsFull}alimentacao`;
      case "SEGURO":
        return `${labelIconItauFonts}seguros`;
      case "BENEFICIOS":
        return `${labelIconItauFontsFull}exclusivo_beneficios`;
      case "CELULAR":
        return `${labelIconItauFontsFull}celular`;
      case "PONTOS":
        return labelIconeProgramaPontos;
      default:
        return labelIconeProgramaPontos;
    }
  }

  const statementsViewModel = props.statements.map(statement => {
    const accumulator = (acc: number, current: number) => acc + current;

    return {
      date: statement.date,
      points: statement?.details?.map(detail => detail.valor).reduce(accumulator),
      details: statement?.details?.map(detail => {
        return {
          icons: retornaIconeCategoria(detail.categoria),
          product: detail.descricao,
          valueInPoints: detail.valor,
          vendorName: detail.vendor_name,
          dataCadastro: detail.data_cadastro,
          categoria: detail.categoria,
          dataExpiracao: detail.data_expiracao,
          idPedido: detail.pedido_id,
          valorReal: detail.monetary_value
        } as IStatementDetailItem
      })
    } as IStatementDateItem
  });

  const [statementsViewModelState, setStatementsViewModelState] = useState(statementsViewModel);
  
  const buttonsNoActive = () => (!buttonActiveDate && !buttonActiveType && !buttonActiveOrder && setClearAllDisabled(true), setIsFilterSelect(false))
  
  function openModal() {
    buttonsNoActive();
    setShow(true);
    setModalOpen(true);
  }

  let navigate = useNavigate();

  const handleNavegateContentKeyEnter = (e: any) => {
    if(e.key === 'Enter'){
      navigate("/");
    }
  };

  function handleFiltrar() {
    setButtonFilterActive(true);
  }

  const dateActiveAndTypeInactive = buttonActiveDate && !buttonActiveType;
  const typeActiveAndDateInactive = !buttonActiveDate && buttonActiveType;
  const dateActiveAndTypeActive = buttonActiveDate && buttonActiveType;

  function filtrar(rangeFilter: number, typeFilter: string, orderFilter: string) {

    const typeFilterEqualLabelCreditados = typeFilter === labelCreditados;
    const typeFilterNotEqualLabelTodos = typeFilter !== labelTodos;
    const typeFilterEqualLabelResgatados = typeFilter === labelResgatados;
    const typeFilterEqualLabelTodos = typeFilter === labelTodos;
    const typeFilterEqualLabelExpirados = typeFilter === labelExpirados;
  
    let filteredData = statementsViewModel.filter((data) => {
      const now = new Date();
      const range = now.setDate(now.getDate() - rangeFilter);
      const date = new Date(data.date).getTime();

      if(dateActiveAndTypeInactive) {
        return date >= range;
      }
      
      if (typeActiveAndDateInactive) {
       
        if (typeFilterNotEqualLabelTodos) {
          if (typeFilterEqualLabelCreditados) {
            return data.details.some(item => {
              return item.valueInPoints > 0;
            })
          }

          if (typeFilterEqualLabelResgatados) {
            return data.details.some(item => {
              return item.valueInPoints < 0 && !item.product.includes(labelPointsExpired);
            })
          }

          if (typeFilterEqualLabelExpirados) {
            return data.details.some(item => {
              return item.product.includes(labelPointsExpired);
            })
          }
        }

        if ( typeFilterEqualLabelTodos ) return true;

      }

      if (dateActiveAndTypeActive) {
       
        if (typeFilterNotEqualLabelTodos) {
          if (typeFilterEqualLabelCreditados) {
            return data.details.some(item => {
              return item.valueInPoints > 0 && date >= range;
            })
          }

          if (typeFilterEqualLabelResgatados) {
            return data.details.some(item => {
              return item.valueInPoints < 0 && date >= range;
            })
          }

          if (typeFilterEqualLabelExpirados) {
            return data.details.some(item => {
              return item.product.includes(labelPointsExpired) && date >= range;
            })
          }
        }

        if ( typeFilterEqualLabelTodos ) {
          return date >= range
        }

      }

      return true;

    }).map(item => {
      let filteredDetails = item.details;
      
      if (buttonActiveType) {
        if (typeFilterEqualLabelCreditados) {
          filteredDetails = item.details.filter(item => item.valueInPoints > 0);
        } else if (typeFilterEqualLabelResgatados) {
          filteredDetails = item.details.filter(item => item.valueInPoints < 0 && item.product !== labelPointsExpired);
        } else if (typeFilterEqualLabelTodos) {
          filteredDetails = item.details;
        } else if (typeFilterEqualLabelExpirados) {
          filteredDetails = item.details.filter(item => item.product === labelPointsExpired)
        }
      }

      return {
        ...item,
        details: filteredDetails
      } 
    })

    if (orderFilter === "mais antigo primeiro") {
      filteredData.reverse();
    }

    if ( filteredData.length === 0 ) {
      setSemResultado(true);
      setButtonFilterActive(false);
    }

    if( filteredData.length > 0 && buttonFilter) {
      setShowCountTags(true);
      setShow(false);
    }
    
    setStatementsViewModelState(filteredData)
    setButtonFilterActive(false);
    setClearButtonDate(false);
    setClearButtonType(false);
    setClearButtonOrder(false);

    countSelectFilterTags === 0 && setShowCountTags(false);
  }

  useEffect(() => {

    filtrar(targetNumberFilterDate, typeFilterActiveName, orderFilterActiveName);

  }, [buttonFilter, clearButtonDate, clearButtonType, clearButtonOrder])

  function handleFilterDate (e: any) {
    let target = e.target.innerHTML;
    let targetNumber = parseInt(target.replace(/ dias/g,''));
    !buttonActiveDate && setCountSelectFilterTags(countSelectFilterTags + 1);
    setTargetNumberFilterDate(targetNumber);    
    setFilterDate(target);
    setButtonActiveDate(true);
    setIsFilterSelect(true);
    setClearAllDisabled(false);
    setSemResultado(false);
  }

  function handleFilterType (e: any) {
    let target = e.target.innerHTML;
    !buttonActiveType && setCountSelectFilterTags(countSelectFilterTags + 1);
    setTypeFilterActiveName(target);
    setFilterType(target);
    setButtonActiveType(true);
    setIsFilterSelect(true);
    setClearAllDisabled(false);
    setSemResultado(false);
  }

  function handleFilterOrder (e: any, name: string) {
    let target = e.target.innerHTML;
    !buttonActiveOrder && setCountSelectFilterTags(countSelectFilterTags + 1);
    setOrderFilterActiveName(name);
    setFilterOrder(target);
    setButtonActiveOrder(true);
    setIsFilterSelect(true);
    setClearAllDisabled(false);
    setSemResultado(false);
  }

  function cleanFilterDate () {
    setClearButtonDate(true);
    setButtonActiveDate(false);
    setFilterDate("");
    setCountSelectFilterTags(countSelectFilterTags-1);
    setTargetNumberFilterDate(0);
  }

  function cleanFilterType () {
    setClearButtonType(true);
    setFilterType("");
    setButtonActiveType(false);
    setTypeFilterActiveName("")
    setCountSelectFilterTags(countSelectFilterTags-1);
  }

  function cleanFilterOrder () {
    setClearButtonOrder(true);
    setButtonActiveOrder(false);
    setFilterOrder("");
    setOrderFilterActiveName("");
    setCountSelectFilterTags(countSelectFilterTags-1);
  }

  function cleanAllFilters() {
    setIsFilterSelect(false);
    cleanFilterDate();
    cleanFilterType();
    cleanFilterOrder();
    setCountSelectFilterTags(0);
    setClearAllDisabled(true);
  }

  return (
    <Fragment>
      <div data-testid="content-tabs" className="d-flex-start-center my-2" style={{overflow: "overlay", height: "60px"}}>
        <button data-testid="filter-button" className="left color-blue" onClick={openModal}>
          <i
            aria-label="Filtrar"
            className="icon icon-itaufonts_filtro ms-2 me-2"
          />
          { !showCountTags && "Filtrar" }
        </button>
        
        { showCountTags && <button className="filter-counter right">{countSelectFilterTags}</button> }
        
        { buttonActiveDate &&
          <button
            role="listitem"
            className="col-auto button-filter active text-left d-flex justify-content-between align-items-center p-2 me-2 pb-1 pt-2 filter-in-page"
            onClick={() => cleanFilterDate()}
          >
            { targetNumberFilterDate } dias
            <i
              aria-label="Remover Filtro"
              className="icon icon_close ms-2 font-size-10"
            />
          </button>
        }
    
        { buttonActiveType &&
            <button
              role="listitem"
              className="col-auto button-filter active text-left d-flex justify-content-between align-items-center p-2 me-2 pb-1 pt-2 filter-in-page"
              onClick={() => cleanFilterType()}
            >
              {typeFilterActiveName}
            <i
              aria-label="Remover Filtro"
              className="icon icon_close ms-2 font-size-10"
            />
          </button>
        }

        { buttonActiveOrder &&
            <button
              role="listitem"
              className="col-auto button-filter active text-left d-flex justify-content-between align-items-center p-2 me-2 pb-1 pt-2 filter-in-page"
              onClick={() => cleanFilterOrder()}
            >
              {orderFilterActiveName}
            <i
              aria-label="Remover Filtro"
              className="icon icon_close ms-2 font-size-10"
            />
          </button>
        }
      </div>
        { statementsViewModelState.map((statement, i) => {
              return (
                <div key={i}>
                  <ul className="list-tabs mx-1">
                    <li>
                      <div className="row mx-2 border-bottom-ddd">
                        <div role="text" className="col-12 mt-2 d-flex">
                          <p className="col txt-left date-capitalize">
                            {" "}
                            <Moment locale="pt-br" format="DD, MMMM YYYY" withTitle>
                              {statement.date}
                            </Moment>
                          </p>
                          
                          { !showCountTags && 
                            <p aria-label={`${statement.points.toLocaleString("pt-BR")} pontos`} className="col txt-right color-ui-new-1">
                              {`${statement.points.toLocaleString("pt-BR")} pts`}
                            </p>
                          }
                          
                        </div>
                      </div>
                      {statement.details.map((valor, index) => {
                        return (
                          <div key={index} onClick={() => {navigate('/extract-details', { state: { ...valor }});}} className="col-12 my-4 d-flex align-items-center row mx-2 btn-list">
                            <div
                              className={`col-2 icon-with-rounded-background w-32 ${
                                valor.valueInPoints > 0
                                  ? "green-background"
                                  : valor.product === labelPointsExpired ? "red-background" : "gray-background"
                              }`}
                            >
                              <i
                                className={`icon ${valor.icons} ${
                                  valor.valueInPoints > 0
                                    ? "color-money"
                                    : valor.product === labelPointsExpired ? "color-red-money" : "color-ui-new-1"
                                }`}
                              ></i>
                            </div>
                            <div className="col txt-left mx-4">
                              <p className="my-0">{valor.product}</p>
                              <p className="color-ui-new-1 my-0 font-size-14">
                                {valor.vendorName}
                              </p>
                            </div>
                            <div className="col txt-right">
                              <p
                                aria-label={`+ ${valor.valueInPoints.toLocaleString()} pontos`}
                                className={`my-0 bold ${
                                  valor.valueInPoints > 0
                                    ? "color-money"
                                    : valor.product === labelPointsExpired ? "color-red-money" : "color-ui-new-1"
                                }`}
                              >
                                {valor.valueInPoints > 0
                                  ? `+ ${valor.valueInPoints.toLocaleString("pt-BR")}`
                                  : valor.valueInPoints.toLocaleString("pt-BR")}{" "}
                                pts
                              </p>
                            </div>
                            <div className="col-1 txt-right d-flex-all-center">
                                <i className="icon icon_right color-ui-new-1 font-size-12"></i>
                            </div>
                          </div>
                        );
                      })}
                    </li>
                  </ul>
                </div>
              );
            })
        }

      <Modal show={show} onHide={() => setShow(false)} data-testid="filter-modal">
        <Fragment>
          <div className="modal-body d-flex justify-content-between">
            <div className="mt-2">
              <h2>{"Filtrar"}</h2>
              { semResultado && <p className="color-red-money"><b>0</b> resultados para os filtros selecionados</p> }
            </div>
            <button onClick={() => {setShow(false)}} type="button" className="btn-close" aria-label="Fechar"></button>
          </div>
        </Fragment>
        <Modal.Body>
          <Fragment>
            <div role="text" className="d-flex-all-center">
              <h2 className="col title-points">período de lançamentos</h2>
            </div>
            <div className="d-flex flex-wrap">
              {viewModel.modalComponent.days.map((date, i) => {
                return (
                  <div
                    data-testid={`optionFilterDate`+i}
                    role="option"
                    id={i.toString()}
                    key={i}
                    className={`p-2 button-filter ${filterDate === date.name ? "active" : ""}`}
                    onClick={(e) => {handleFilterDate(e)}}
                    aria-selected={filterDate === date.name ? true : false}
                  >
                    {date.name}
                  </div>
                )
              })}
            </div>
            <div role="text" className="d-flex-all-center mt-3">
              <h2 className="col title-points">tipos de lançamentos</h2>
            </div>
            <div className="d-flex flex-wrap">
              {viewModel.modalComponent.types.map((tip, i) => {
                return (
                  <div
                    role="option"
                    id={i.toString()}
                    key={i}
                    className={`col button-filter ${filterType === tip.name ? "active" : ""}`}
                    onClick={(e) => {handleFilterType(e)}}
                    aria-selected={filterType === tip.name ? true : false}
                  >
                    {tip.name}
                  </div>
                )
              })}
            </div>
            <div role="text" className="d-flex-all-center mt-3">
              <h2 className="col title-points">ordem de exibição</h2>
            </div>
            <div className="d-flex justify-content-around">
              {viewModel.modalComponent.order.map((part, i) => {
                return (
                  <div
                    role="option"
                    id={i.toString()}
                    key={i}
                    className={`col m-0 button-filter ${i === 0 ? "me-2" : ""} ${filterOrder === part.name ? "active" : ""}`}
                    onClick={(e) => {handleFilterOrder(e, part.name)}}
                    aria-selected={filterOrder === part.name ? true : false}
                  >
                    {part.name}
                  </div>
                )
              })}
            </div>

            <div className="d-flex flex-wrap mt-4">

              <button
                className={'col clean-filters me-3'}
                onClick={cleanAllFilters}
                data-testid='btn-submit'
                disabled={clearAllDisabled}
              >
                limpar filtros
              </button>

              { semResultado === true ?
                <button
                  className={isLoading ? 'submit-filters hide-txt' : 'submit-filters col'}
                  disabled={true}
                >
                  sem resultado
                </button>
              : 
                <button
                  className={isLoading ? 'submit-filters hide-txt' : 'submit-filters col'}
                  onClick={() => {handleFiltrar()}}
                  data-testid='btn-submit'
                  disabled={!isFilterSelect}
                >
                  filtrar
                </button> 
              }
              { isLoading && <span className="loading"></span> }
            </div>
          </Fragment>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ContentTabs;