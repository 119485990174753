import React from "react";
import AnalyticsService from "./Analytics/AnalyticsService.injector";
import ExtractService from "./Extract/ExtractService.injector";
import FeatureToggleService from "./FeatureToggle/FeatureToggleService.injector";
import NativeCommunicationService from "./NativeCommunication/NativeCommunicationService.injector";
import SecurityService from "./Security/SecurityService.injector";

const GlobalServices: React.FC<any> = ({children}: any) => {
  return (
    <>
      <NativeCommunicationService>
        <SecurityService>
          <AnalyticsService>
            <FeatureToggleService>
              <ExtractService>
                {children}
              </ExtractService>
            </FeatureToggleService>
          </AnalyticsService>
        </SecurityService>
      </NativeCommunicationService>
    </>
  );
}

export default GlobalServices;
