import React, { FC } from "react";
import Contextualizer from "../Contextualizer";
import ProvidedServices from "../ProvidedServices";
import NativeCommunicationServiceImpl from "./NativeCommunicationService";
import INativeCommunicationService from "./NativeCommunicationService.interface";

const NativeCommunicationServiceContext = Contextualizer.createContext(ProvidedServices.NativeCommunicationService);

const NativeCommunicationService: FC<any> = ({children}: any) => {
  const NativeCommunicationService: INativeCommunicationService = new NativeCommunicationServiceImpl();

  return (
    <>
      <NativeCommunicationServiceContext.Provider value={NativeCommunicationService}>
        {children}
      </NativeCommunicationServiceContext.Provider>
    </>
  )
}

export default NativeCommunicationService;
