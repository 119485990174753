import React, { FC } from "react";
import { useNativeCommunicationService } from "../../hooks/useNativeCommunicationService";
import Contextualizer from "../Contextualizer";
import ProvidedServices from "../ProvidedServices";
import FeatureToggleServiceImpl from "./FeatureToggleService";
import IFeatureToggleService from "./FeatureToggleService.interface";

const FeatureToggleServiceContext = Contextualizer.createContext(ProvidedServices.FeatureToggleService);

const FeatureToggleService: FC<any> = ({children}: any) => {
  const nativeCommunicationService = useNativeCommunicationService();
  const featureToggleService: IFeatureToggleService = new FeatureToggleServiceImpl(nativeCommunicationService);

  return (
    <>
      <FeatureToggleServiceContext.Provider value={featureToggleService}>
        {children}
      </FeatureToggleServiceContext.Provider>
    </>
  )
}

export default FeatureToggleService;
