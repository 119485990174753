import { Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import INativeCommunicationService from '../NativeCommunication/NativeCommunicationService.interface';
import ISecurityService, { IHashedCpfResponse } from './SecurityService.interface';

export default class SecurityServiceImpl implements ISecurityService {
  constructor(
    private nativeService: INativeCommunicationService
  ) { }

  getHashedCPF(): Observable<string> {
    return this.nativeService.getMemoryItem<string>('hashed_cpf')
      .pipe(
        take(1),
        mergeMap(hashedCpf => {
          if (hashedCpf == undefined|| hashedCpf == "") {
            return this.getHashedCPFByBacked();
          }

          return of(hashedCpf);
        })
    );
  }

  getHashedCPFByBacked(): Observable<string> {
    return this.nativeService.getMemoryItem<string>('cpf')
      .pipe(
        take(1),
        mergeMap(cpf => {
          return this.nativeService.routerRequest({
            op: 'segurancaCriptografia',
            method: 'GET',
            query: [{
              key: 'sens_data',
              value: cpf,
            }]
          })
        }),
        map(response => (response as IHashedCpfResponse).hashed_data),
      );
  }
}
