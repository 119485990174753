import React, { Fragment, useEffect, useState } from "react";
import Carousel from '../../components/carousel/Carousel';
import CardComponet from '../../components/card/CardComponet';
import CardImg from "../../components/card/CardImg";
import { MockBannerNotPoints } from "../../mock/MockBannerNotPoints"
import { MockImgCard } from "../../mock/MockImgCard"
import { HomeCard } from "../../components/HomeCard/HomeCard";
import { useAnalyticsService } from "../../shared/hooks/useAnalyticsService";
import useEffectOnlyOnce from "../../shared/hooks/useEffectOnlyOnce";

const NotPoints = () => {
  // TODO: Carregar taguemanto no onload da pagina
  // Todo: Trazer o RouterRequest para preencher dados de Valor, Cash, Info
  const analyticsService = useAnalyticsService();
  const analyticsPageName = 'IUPPTelaExtratoNuncaPontuou';
  const [isTagged, setIsTagged] = useState(false);

  function onNoPointsButtonTapped(e: any) {
    const buttonName = e.target.innerHTML;
    let eventLabel = analyticsService.captalizeAndRemoveSpaces(buttonName);

    analyticsService.trackAction(analyticsPageName, eventLabel);
  }

  useEffectOnlyOnce(
    () => {
      analyticsService.trackState(analyticsPageName);
    },
    [isTagged],
    (dependencies: boolean[]) => dependencies[0] === false
  );

  return (
    <Fragment>
      <Carousel data={MockBannerNotPoints}/>
      <CardComponet />
        <HomeCard />
        <div tabIndex={0} className="col-12 row">
          <div className="m-app txt-left mt-4">
            <p className="color-no-points">Sem pontos?</p>
          </div>
          <div className="m-app txt-left mt-1">
            <h2 className="font-size-24">Comece agora a ganhar pontos e usa-los em viagens, cashback e mais!</h2>
          </div>
        </div>
      <CardImg mock={MockImgCard}/>
      <div className="m-app mb-4">
        <button className="clean-filters" onClick={onNoPointsButtonTapped}>quero começar a ganhar pontos</button>
      </div>
    </Fragment>
  );
};

export default NotPoints;
