import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";

const WherePointsCome = () => {
  // TODO: Carreguar tagueamento no load da pagina

  function adobeTrackStateWherePointsCome() {
    const analyticsObj = {
      "page": {
          "name": "WherePointsCome",
          "statusLogin": "Logado"
      },
      "visitor": {
          // "segmentoConta": native.getMemoryItem('segmento')
      },
      "rule": "pageLoad"
    };
    console.log(analyticsObj)
  }

  let navigate = useNavigate();

  const handleToHomeWhere = () => {
    navigate('/')
  }

  useEffect(() => {
    adobeTrackStateWherePointsCome()
  }, []);

  return (
    <div className="m-app">
      <Row>
        <div className='d-flex-start-center mt-5' onClick={handleToHomeWhere}>
          <div className="col-1 txt-left">
              <i className="icon-itaufonts_seta color-ui-new-1 font-size-24"></i>
          </div>
          <div className='col txt-left'>
            entenda de onde vem seus pontos
          </div>
        </div>
        <div className="col mb-4 mt-5 txt-left">
          <p className="font-size-24 color-title">Os pontos disponíveis nesse <br /> extrato podem ter vindo por esses caminhos:</p>
        </div>
        <div className="col-12 mt-1 txt-left color-no-points">
          <ol className="ps-3">
            <li className="mb-4">
              <p className="ms-2">
                Adesão, contratação, compra ou uso de determinados produtos ou serviços dos parceiros da Plataforma iupp,
                tais como: utilização dos cartões de crédito vinculados à Plataforma iupp, compras no Shopping iupp, entre outros;
              </p>
            </li>
            <li className="mb-4">
              <p className="ms-2">Participação em atividades ou campanhas de incentivo, promoções da Plataforma iupp e/ou de seus parceiros.</p>
            </li>
            <li className="mb-4">
              <p className="ms-2">Compra de pontos diretamente na Plataforma iupp.</p>
            </li>
            <li className="mb-4">
              <p className="ms-2">
                Clientes portadores de cartões de crédito Itaucard das variantes Internacional, Gold, Platinum,
                Signature, Black e Infinite, exceto os cartões de parcerias, que possuem programas próprios,
                são elegíveis a pontuar na Plataforma iupp.
              </p>
            </li>
          </ol>
        </div>
      </Row>
    </div>
  )
}

export default WherePointsCome
