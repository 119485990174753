import React, { useState } from "react";
import PropTypes from "prop-types";
import "./estilo.css";
import { ICarouselProps } from "./Carousel.interface";
import { useAnalyticsService } from "../../shared/hooks/useAnalyticsService";
import { useNativeCommunicationService } from "../../shared/hooks/useNativeCommunicationService";
import HTMLReactParser from "html-react-parser";

const Carousel = (props: ICarouselProps ) => {
  const nativeCommunicationService = useNativeCommunicationService();
  const analyticsPageName = 'IUPPTelaExtrato';
  const analyticsService = useAnalyticsService();

  const [slide1, setSlide1] = useState(true)
  const [slide2, setSlide2] = useState(false)
  const [slide3, setSlide3] = useState(false)

  function onBannerCarouselTapped(index: number, url: string) {
    const eventLabel = `BANNER:${analyticsPageName}:Banner0${index+1}`;

    analyticsService.trackAction(analyticsPageName, eventLabel);

    window.open(url);
  }

  function onPreviousButtonTapped() {
    analyticsService.trackAction(analyticsPageName, 'VoltarBanner');
  }

  function onNextButtonTapped() {
    analyticsService.trackAction(analyticsPageName, 'AvancarBanner');
  }

  function InfoSlide(n: number){
    if(n === 1){
      setSlide1(true)
      setSlide2(false)
      setSlide3(false)
    }else if(n === 2){
      setSlide1(false)
      setSlide2(true)
      setSlide3(false)
    }else if(n === 3){
      setSlide1(false)
      setSlide2(false)
      setSlide3(true)
    }
  }

  function canShowItauShop(): boolean {
    var isiOS = window.navigator.userAgent.match(/(iphone|ipad)/gi);
    var isSuperApp = analyticsService.getBankChannel() == "SUPERAPP";
    
    return !(isiOS && isSuperApp);
  }

  function canShowCreditoFaturaAndroid(): boolean {
    var isAndroid = window.navigator.userAgent.match(/(android)/gi);
    
    return isAndroid !== null ? true : false;
  }

  function itauShopButtonTapped() {
    nativeCommunicationService.itauShop("itau_shop?route=/");
    analyticsService.trackAction(analyticsPageName, 'IrParaItauShop');
  }

  function creditoFaturaButtonTapped() {
    nativeCommunicationService.pointsCashbackDeeplink("/credito-na-fatura");
    analyticsService.trackAction(analyticsPageName, 'IrParaCreditoFatura');
  }

  function stixButtonTapped() {
    nativeCommunicationService.pointsCashbackDeeplink("/stix");
    analyticsService.trackAction(analyticsPageName, 'IrParaStix');
  }

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-bs-touch="false"
      data-bs-ride="false"
    >
      <div className="carousel-inner">
        {props.data.map((valor, index) => {
          let descriptionWithoutBold = valor.description.replace("<b>", "");
          descriptionWithoutBold = descriptionWithoutBold.replace("</b>", "");
          
          return (
            <div
              role="banner"
              data-bs-interval="999999"
              // pause="hover"
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              key={index}
            >
              <img
                aria-hidden="true"
                src={valor.urlImg}
                className="img-carousel"
                alt={`${valor.title} ${descriptionWithoutBold}`}
              />
              { index === 0 && 
                <div className="carousel-caption carousel-caption-itau-shop d-md-block">
                  <div>
                    <p
                      aria-label={`${valor.title} ${descriptionWithoutBold}`}
                      className="txt-title-carrousel mt-3"
                    >
                      <span className="mb-0 mt-1 mb-2 txt-title-carrousel-big-bold">{valor.title}</span>
                      <span>{ HTMLReactParser(valor.description) }</span>
                    </p>
                  </div>
                  {valor.originalPrice && (
                    <div>
                      <div
                        aria-label={`de ${valor.originalPrice} pontos`}
                        className="txt-little-price-carrousel txt-line-through mb-0 brc"
                      >
                        {`de ${valor.originalPrice} pts`}
                      </div>
                    </div>
                  )}
                  {valor.newPrice && (
                    <div>
                      <span
                        aria-label={`por ${valor.newPrice} pontos`}
                        className="txt-big-price-carrousel mb-0 brc"
                      >
                        {`por ${valor.newPrice} pts`}
                      </span>
                    </div>
                  )}
                  <button className='btn-itauShop mt-1' onClick={() => {itauShopButtonTapped()}}>ir para o Itaú Shop</button>
                </div>
              }
              { index === 1 && 
                <div className="carousel-caption carousel-caption-credito-fatura d-md-block">
                  <div aria-hidden="true" className={valor.icon}></div>
                  <div>
                    <p
                      aria-label={`${valor.title} ${descriptionWithoutBold}`}
                      className="txt-title-carrousel mt-3"
                    >
                      <span className="mb-0 mt-1 txt-title-carrousel-medium-bold">{valor.title}</span>
                      <span className="font-size-22">{ HTMLReactParser(valor.description) }</span>
                    </p>
                  </div>
                  {valor.originalPrice && (
                    <div>
                      <div
                        aria-label={`de ${valor.originalPrice} pontos`}
                        className="txt-little-price-carrousel txt-line-through mb-0 brc"
                      >
                        {`de ${valor.originalPrice} pts`}
                      </div>
                    </div>
                  )}
                  {valor.newPrice && (
                    <div>
                      <span
                        aria-label={`por ${valor.newPrice} pontos`}
                        className="txt-big-price-carrousel mb-0 brc"
                      >
                        {`por ${valor.newPrice} pts`}
                      </span>
                    </div>
                  )}
                  <button className='btn-itauShop mt-1' onClick={() => {creditoFaturaButtonTapped()}}>ir para Desconto na Fatura</button>
                </div>
              }
              { index === 2 && 
                <div className="carousel-caption carousel-caption-stix d-md-block">
                  <div aria-hidden="true" className={valor.icon}></div>
                  <div>
                    <p
                      aria-label={`${valor.title} ${descriptionWithoutBold}`}
                      className="txt-title-carrousel mt-3"
                    >
                      <span className="mb-0 mt-1">{valor.title}</span>
                      <span>{ HTMLReactParser(valor.description) }</span>
                    </p>
                  </div>
                  {valor.originalPrice && (
                    <div>
                      <div
                        aria-label={`de ${valor.originalPrice} pontos`}
                        className="txt-little-price-carrousel txt-line-through mb-0 brc"
                      >
                        {`de ${valor.originalPrice} pts`}
                      </div>
                    </div>
                  )}
                  {valor.newPrice && (
                    <div>
                      <span
                        aria-label={`por ${valor.newPrice} pontos`}
                        className="txt-big-price-carrousel mb-0 brc"
                      >
                        {`por ${valor.newPrice} pts`}
                      </span>
                    </div>
                  )}
                  <button className='btn-itauShop mt-1' onClick={() => {stixButtonTapped()}}>transferir para Stix</button>
                </div>
              }
            </div>
          );
        })}
      </div>

      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
        onClick={() => onPreviousButtonTapped()}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Anterior</span>
      </button>

      <div 
        className="carousel-indicators" 
        role="listbox"
        aria-label="Slides"
      >
        <button
          onClick={() => InfoSlide(1)}
          role="option"
          aria-selected={slide1}
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="slide 1"
        ></button>
        <button
          onClick={() => InfoSlide(2)}
          role="option"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-selected={slide2}
          aria-current="false"
          aria-label="slide 2"
        ></button>
        <button
          onClick={() => InfoSlide(3)}
          role="option"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-selected={slide3}
          aria-current="false"
          aria-label="slide 3"
        ></button>
      </div>

      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
        onClick={() => onNextButtonTapped()}
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Próximo</span>
      </button>
    </div>
  );
};

Carousel.propTypes = {
  mock: PropTypes.object
};

export default Carousel;