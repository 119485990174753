import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const WhyPointsExpire = () => {
  // TODO: Carreguar tagueamento no load da pagina

  function adobeTrackStateWhyPointsExpire() {
    const analyticsObj = {
      "page": {
          "name": "WhyPointsExpire",
          "statusLogin": "Logado"
      },
      "visitor": {
          // "segmentoConta": native.getMemoryItem('segmento')
      },
      "rule": "pageLoad"
    };
    console.log(analyticsObj)
  }

  useEffect(() => {
    adobeTrackStateWhyPointsExpire()
  }, []);

  let navigate = useNavigate();

  const handleWherePointsCome = () => {
    navigate("/");
  };

  return (
    <div className="m-app">
      <Row>
        <Col xs={12} className="mb-4 header-internal mt-5" onClick={handleWherePointsCome}>
          <div className="col-1 txt-left">
            <i className="icon-itaufonts_seta color-ui-new-1 font-size-24" />
          </div>
        </Col>
        <Col xs={12} className="mt-1 txt-left">
          <h1 className="font-size-20">Como funciona a validade dos pontos iupp</h1>
        </Col>
        <Col xs={12} className="mt-3 txt-left">
          <ol className="ps-3">
            <li className="mb-4">
              <p className="ms-2">A validade dos Pontos iupp se dará conforme termos e condições descritos em regulamento específico de cada forma de acúmulo.</p>
            </li>
            <li className="mb-4">
              <p className="ms-2">Pontos iupp acumulados <b>por meio de compra de pontos</b> não expiram.</p>
            </li>
            <li className="mb-4">
              <p className="ms-2">
                Pontos iupp acumulados <b>por meio de compras no Shopping iupp</b> têm validade de 24 meses, a partir da data em que forem creditados
                na conta iupp do Participante, exceto quando acumulados em razão de campanhas promocionais com regras próprias. Decorrido tal prazo,
                os Pontos iupp não utilizados serão automaticamente cancelados.
              </p>
            </li>
            <li className="mb-4">
              <p className="ms-2">
                A Plataforma poderá realizar <b>campanhas promocionais</b> de concessão de Pontos iupp cuja
                validade será determinada pela regra própria da referida campanha e disponibilizada ao Participante em regulamento próprio.
              </p>
            </li>
            <li className="mb-4">
              <p className="ms-2">
                Caso o Titular cancele algum ou todos os seus cartões de crédito, <b>os pontos referentes ao cartão cancelado</b>
                não serão afetados, desde que o Titular mantenha a conta na Plataforma ativa. As parcelas de compras que vierem a
                ser lançadas na fatura após o cancelamento do cartão não serão consideradas para fins de acúmulo de pontos.
              </p>
            </li>
            <li className="mb-4">
              <p className="ms-2">
                Sobre <b>pontos iupp acumulados através de compras com cartões de crédito</b>,
                confira na tabela abaixo o tempo de expiração para cada cenário:
              </p>
            </li>
          </ol>
        </Col>
        <Col xs={12} className="mt-3 txt-left">
          <h2>Cartões <b>Itaú</b></h2>
          <hr />
          <ul className="ps-3">
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Internacional</b></Col>
                <Col xs={6} className="txt-right">24 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Gold</b></Col>
                <Col xs={6} className="txt-right">24 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Click + pontos</b> (sem versão turbinada)</Col>
                <Col xs={6} className="txt-right">24 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Click + pontos</b> (com versão turbinada)</Col>
                <Col xs={6} className="txt-right">36 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Platinum</b> (com versão simples e turbinada)</Col>
                <Col xs={6} className="txt-right">36 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Black</b></Col>
                <Col xs={6} className="txt-right">36 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Infinite</b></Col>
                <Col xs={6} className="txt-right">36 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Samsung Itaucard Plus</b></Col>
                <Col xs={6} className="txt-right">36 meses</Col>
              </Row>
            </li>
          </ul>
        </Col>
        <Col xs={12} className="mt-3 txt-left">
          <h2>Cartões <b>Uniclass</b></h2>
          <hr />
          <ul className="ps-3">
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Gold</b></Col>
                <Col xs={6} className="txt-right">24 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Platinum</b></Col>
                <Col xs={6} className="txt-right">36 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Platinum</b></Col>
                <Col xs={6} className="txt-right">36 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Black</b></Col>
                <Col xs={6} className="txt-right">36 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Infinite</b></Col>
                <Col xs={6} className="txt-right">36 meses</Col>
              </Row>
            </li>
          </ul>
        </Col>
        <Col xs={12} className="mt-3 txt-left">
          <h2>Cartões <b>Personnalité</b></h2>
          <hr />
          <ul className="ps-3">
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Gold</b></Col>
                <Col xs={6} className="txt-right">24 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Platinum</b></Col>
                <Col xs={6} className="txt-right">36 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Signature</b></Col>
                <Col xs={6} className="txt-right">36 meses</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Black Pontos</b></Col>
                <Col xs={6} className="txt-right">Nunca</Col>
              </Row>
            </li>
            <li className="mb-2">
              <Row>
                <Col xs={6} className="txt-left"><b>Infinite</b></Col>
                <Col xs={6} className="txt-right">Nunca</Col>
              </Row>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  )
}

export default WhyPointsExpire
