import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalServices from "./shared/services/GlobalServices";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <GlobalServices>
      <App />
    </GlobalServices>
  </React.StrictMode>
);

reportWebVitals();
