import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import LogoInvoice from "../../Styles/img/icons/icon-invoice-credit.png"

const InvoiceCredit = () => {
  // TODO: Carregar tagueamento

  function adobeTrackStateCredit() {
    const analyticsObj = {
      "page": {
          "name": "InvoiceCredit",
          "statusLogin": "Logado"
      },
      "visitor": {
          // "segmentoConta": native.getMemoryItem('segmento')
      },
      "rule": "pageLoad"
    };
    console.log(analyticsObj)
  }

  useEffect(() => {
    adobeTrackStateCredit()
  }, []);

  let navigate = useNavigate();

  const handleToHomeInvoiceCredit = () => {
    navigate('/')
  }
  
  return (
    <div className='m-app'>
      <Row>
        <Col xs={12} className="mt-5 mb-4 header-internal">
          <div className="col-1 txt-left">
            <i className="icon-itaufonts_seta color-ui-new-1 font-size-24" onClick={handleToHomeInvoiceCredit} />
          </div>
        </Col>
        <Col xs={12} className="mt-4 txt-left">
          <img src={LogoInvoice} alt="" />
        </Col>
        <Col xs={12} className="mt-3 txt-left">
          <p className="font-size-24 mb-0">
            Crédito na fatura Itaucard <br />
            Uniclass 0390
          </p>
        </Col>
        <Col xs={12} className="mt-0 txt-left">
          <p className="font-size-16 color-no-points">11 de fevereiro de 2022 às 13h10</p>
        </Col>
          <div className="col-12 my-4 d-flex-start-center">
            <div className="col txt-left">
              <p className="my-0">Pontos creditados</p>
            </div>
            <div className="col txt-right">
              <p className="my-0 color-no-points">900 pts</p>
            </div>
          </div>
          <div className='border-bottom-ddd'></div>
          <div className="col-12 my-4 d-flex-start-center">
            <div className="col txt-left">
              <p className="my-0">categoria</p>
            </div>
            <div className="col txt-right">
              <p className="my-0 color-no-points">crédito em fatura</p>
            </div>
          </div>
        <div className='mt-5 mb-3'>
          <button className='clean-filters'>
            ir para o pedido
          </button>
        </div>
      </Row>
    </div>
  )
}

export default InvoiceCredit
