import React, { Fragment } from "react";
import "../card/estilo.css";
import { IHomeCardViewModelProps } from "./HomeCard.interface";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

export const HomeCard = (props: IHomeCardViewModelProps) => {
  return (
    <Fragment>
      {props?.infosHomeCard?.valueInPoints != undefined ? (
        <Fragment>
          <div className="row">
            <div className="container">
              <div className="col">
                <div className="title-points m-app">
                  <h2 className="title-points">total de pontos</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="container">
              <div className="HomeCard">
                <div className="col-12 my-3 mx-4 txt-left">
                  <div className="icon-itaufonts_programa_de_pontos color-brand-1 font-size-32"></div>
                </div>
                <div className="Context">
                  <div className="valor row">
                    <div className="left col-7">
                      <b>Pontos disponíveis</b>
                    </div>
                    <div
                      className="right col-5"
                      aria-label={`${props?.infosHomeCard?.valueInPoints} pontos`}
                    >
                      <b>
                        {props?.infosHomeCard?.valueInPoints?.toLocaleString(
                          "pt-BR"
                        )}
                        {props?.infosHomeCard?.valueInPoints === 0
                          ? ""
                          : " pts"}
                      </b>
                    </div>
                  </div>
                  {props?.infosHomeCard?.valueInCash != undefined && (
                    <div className="saldo row">
                      <div className="left col-6 color-ui-new-1 txt-left">
                        valor no Itaú Shop
                      </div>
                      <div className="right col-6 color-ui-new-1 txt-right">
                        {props?.infosHomeCard?.valueInCash.toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="border-bottom-ui-1 mx-3 d-none"></div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mt-5 mb-5">
            <ErrorMessage
              title="algo deu errado :("
              subtitle="Não foi possível carregar as informações dos seus pontos"
              icons="icon-itaufonts_exclamacao"
              retry={props.retryHandler}
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
