export const MockImgCard = [
    {
        "title": "Produtos",
        "info": "ofertas",
        "description": "<b>Compre as melhores marcas</b> e ganhe 1 ponto a cada real gasto",
        "buttonTxt": "confira",
        "urlImg": "imgCard1NaoPontua",
        "url": "https://www.iupp.com.br/redirect/cash?utm_source=canal_proprietario&utm_medium=mobile&utm_campaign=iupp-vendas-shopping-interno&utm_content=visitantes-warning-shop_iupp-shop"
    },
    {
        "title": "Viagens",
        "description": "<b>Faça sua viagem com o Itaú</b> e acumule pontos",
        "info": undefined,
        "urlImg": "imgCard2NaoPontua",
        "buttonTxt": "confira",
        "url": "https://www.iupp.com.br/redirect/cvc/pacotes-turisticos?utm_source=canal_proprietario&utm_medium=mobile&utm_campaign=iupp-vendas-pacotesturisticos-interno&utm_content=visitantes-warning-pacotes-pacotes"
    },
    {
        "title": "Cartão de crédito",
        "description": "Peça seu <b>Itaucard</b> hoje mesmo e <b>ganhe pontos a cada compra</b>",
        "info": "pra você",
        "urlImg": "imgCard3NaoPontua",
        "buttonTxt": "confira",
        "url": "https://www.itau.com.br/cartoes/escolha/?utm_source=canal_proprietario&utm_medium=mobile&utm_campaign=iupp-vendas-cartoes-interno&utm_content=visitantes-warning-cartoesitau-cartoesitau"
    }
]
