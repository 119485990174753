import img1 from "../Styles/img/notScored.png"
import { ICarouselItem } from "../components/carousel/Carousel.interface"

export const MockBannerNotScored: ICarouselItem[] = [
    {
        "icon": "icon_promo",
        "title": "Quer mais pontos",
        "description": "Peça o seu itaucard Platinum Master e pontue em cada fatura paga",
        "urlImg": img1,
        "eventLabel": "QueroConhecer",
        "buttonTxt": "quero conhecer",
        "URL": "https://www.iupp.com.br/redirect/cash?utm_source=canal_proprietario&utm_medium=mobile&utm_campaign=iupp-vendas-shopping-interno&utm_content=visitantes-warning-shop_iupp-shop"
    }
]
