import img1 from '../Styles/img/Base01.png';
import img2 from '../Styles/img/Base02.jpeg';
import img3 from '../Styles/img/Base03.jpeg';
import { ICarouselItem } from '../components/carousel/Carousel.interface';

export const CarouselData: ICarouselItem[] = [
    {
        "icon": "icon-banner icon-itaufonts_full_exclusivo_beneficios",
        "title": "Itaú Shop",
        "description": "Use seu saldo de pontos no Itaú Shop e aproveite ofertas pensadas pra você.",
        "urlImg": img1,
        "originalPrice": undefined,
        "newPrice": undefined,
        "buttonTxt": "ver ofertas",
        "eventLabel": "Confira",
        "URL": "https://www.iupp.com.br/showcase/?identifier=2-5k-pts&type=5&param=homepage_banner6box_1_catalogo-todos_2,5k_20220803-20220817?utm_source=canal_proprietario&utm_medium=mobile&utm_campaign=iupp-conversao-banner_extratodepontos-externo&utm_content=ga-warning-app_superapp_e_itaucard-ofertas2500pontos"
    },
    {
        "icon": "icon-banner icon-itaufonts_recarga",
        "title": "Que tal aliviar as despesas?",
        "description": "Use seus pontos para ter desconto na fatura do seu cartão Itaú",
        "urlImg": img2,
        "buttonTxt": "conferir",
        "eventLabel": "Confira",
        "URL": "https://www.iupp.com.br/invoice-credit?utm_source=canal_proprietario&utm_medium=mobile&utm_campaign=iupp-conversao-banner_extratodepontos-externo&utm_content=ga-warning-app_superapp_e_itaucard-crédito_em_fatura"
    },
    {
        "icon": "icon-banner icon-itaufonts_compras_vendas",
        "title": "",
        "description": "Economize em supermercados e farmácias, transfira seus pontos para a Stix!",
        "urlImg": img3,
        "buttonTxt": "conferir",
        "eventLabel": "Confira",
        "URL": "https://www.iupp.com.br/invoice-credit?utm_source=canal_proprietario&utm_medium=mobile&utm_campaign=iupp-conversao-banner_extratodepontos-externo&utm_content=ga-warning-app_superapp_e_itaucard-crédito_em_fatura"
    }
]
