import React, { Fragment, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ContestExtract = () => {
  // TODO: Carreguar tagueamento no load da pagina

  let navigate = useNavigate();

  function adobeTrackStateContestExtract() {
    const analyticsObj = {
      "page": {
          "name": "ContestExtract",
          "statusLogin": "Logado"
      },
      "visitor": {
          // "segmentoConta": native.getMemoryItem('segmento')
      },
      "rule": "pageLoad"
    };
    console.log(analyticsObj)
  }

  useEffect(() => {
    adobeTrackStateContestExtract()
  }, []);

  function adobeTrackAction() {
    const analyticsObj = {
        "eventCategory": "Clique em abra um chamado",
        "eventAction": "Exemplo",
        "eventLabel1": "BTN:SDK-WebView:Exemplo 1"
    }
    console.log(analyticsObj);
  }

  function adobeTrackActionCall() {
    const analyticsObjCall = {
        "eventCategory": "Clique em ligar 1",
        "eventAction": "Exemplo",
        "eventLabel2": "BTN:SDK-WebView:Exemplo 2"
    }
    console.log(analyticsObjCall);
  }

  function adobeTrackActionCallTwo() {
    const analyticsObjCallTwo = {
        "eventCategory": "Clique em ligar 2",
        "eventAction": "Exemplo",
        "eventLabel3": "BTN:SDK-WebView:Exemplo 3"
    }
    console.log(analyticsObjCallTwo);
  }

  function adobeTrackActionCallThree() {
    const analyticsObjCallThree = {
        "eventCategory": "Clique em ligar 3",
        "eventAction": "Exemplo",
        "eventLabel4": "BTN:SDK-WebView:Exemplo 4"
    }
    console.log(analyticsObjCallThree);
  }

  function retryHandler() {
    navigate('/')
  }

  return (
    <Fragment>
      <div className="m-app">
        <Col xs={12} className="my-5 header-internal">
          <div className='d-flex-start-center mt-5' onClick={retryHandler}>
            <div className="col-1 txt-left">
                <i className="icon icon_left color-ui-new-1 font-size-12"></i>
            </div>
            <div className='col txt-left'>
              contestar extrato
            </div>
          </div>
        </Col>
        <Col tabIndex={0} xs={12} className="mt-1 txt-left">
          <h2 className="font-size-24">Fale com nosso time de<br /> atendimento</h2>
        </Col>
        <Col xs={12} className="mt-4 txt-left">
          <p tabIndex={0} className="mb-2 color-no-points mb-2"><b>Fale com a gente</b></p>
          <p onClick={() => adobeTrackAction()} className="d-flex pointer txt-decoration-none font-size-16 color-blue bold">
            <i className="icon-custom icon_open_new_window_blue me-2" />
            Abra um chamado pela internet
          </p>
        </Col>
        <Col xs={12} className="mt-4 txt-left">
          <p tabIndex={0} className="mb-2 color-no-points"><b>Se você estiver em uma capital, ligue:</b></p>
          <a href='tel:40901675' onClick={() => adobeTrackActionCall()} className="d-flex pointer txt-decoration-none color-blue bold">
            <i className="icon-custom icon_phone_blue me-2" />
            4090 1675
          </a>
        </Col>
        <Col xs={12} className="mt-4 txt-left">
          <p tabIndex={0} className="mb-2 color-no-points"><b>Se você estiver em outro lugar, ligue:</b></p>
          <a href='tel:08007751675' onClick={() => adobeTrackActionCallTwo()} className="d-flex pointer txt-decoration-none color-blue bold">
            <i className="icon-custom icon_phone_blue me-2" />
            0800 775 1675
          </a>
          <p tabIndex={0} className="mt-2 color-no-points">Segunda a sábado, das 06:00 às 22:00h</p>
        </Col>
        <Col xs={12} className="mt-4 txt-left">
          <p tabIndex={0} className="mb-2 color-no-points"><b>Ouvidoria</b></p>
          <a href='tel:08005700011' onClick={() => adobeTrackActionCallThree()} className="d-flex pointer txt-decoration-none color-blue bold">
            <i className="icon-custom icon_phone_blue me-2" />
            0800 570 0011
          </a>
          <p tabIndex={0} className="mt-2">Segunda a sexta, das 09:00 às 18:00h</p>
        </Col>
      </div>
    </Fragment>
  )
}

export default ContestExtract
