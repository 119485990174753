import React, { Fragment } from 'react'
import './estilo.css'

const ErrorMessage = ( props: IErrorMessage ) => {
    
  return (
    <Fragment>
        <div data-testid='btn-track' className='m-app content-error txt-center'>
        <div aria-hidden="true" className={`font-size-48 ${props.icons}`} />
          <h3 className='title font-size-24 mt-3'>
            {props.title}
          </h3>
          <p className='font-size-16 color-black mt-3'>
            {props.subtitle}
          </p>
          {props.retry && <button className='clean-filters' onClick={props.retry}>tentar novamente</button>}
          
        </div>
    </Fragment>
  )
}

interface IErrorMessage {
  title: string;
  subtitle: string;
  icons: string;
  retry?: () => void;
}

export default ErrorMessage;