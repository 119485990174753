import { Observable, ReplaySubject, Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import INativeCommunicationService, { INativeObject, INativeRequest, NativeCallback } from "./NativeCommunicationService.interface";

export default class NativeCommunicationService implements INativeCommunicationService {
  private sdkObject: INativeObject;

  constructor() {
    if (environment.production) {
      this.sdkObject = (window as any).native;
    } else {
      this.sdkObject = (window as any).native ?? environment.sdkObjectMock;
    }
  }

  /**
   * Gera um valor de texto aleatório de acordo com a quantidade de caracteres informados
   *
   * @param hashSize Tamanho em caracteres do hash
   * @return Retorna o hash gerado dinamicamente
   */
   private generateHash(hashSize = 16): string {
    const tokens = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let hash = '';
    let index = 0;

    for (; index < hashSize; index++) {
      hash += tokens.charAt(Math.floor(Math.random() * tokens.length));
    }

    if ((window as any)[hash]) {
      return this.generateHash();
    }

    return hash;
  }

  /**
   * Limpa referências de callbacks que podem surgfir após o retorno do nativo
   *
   * @param observer Observer que deve ter sua inscrição cancelada
   * @param callbackName Função global que deve ser liberada da memória
   */
   private clearCallback(observer: Subject<any>, callbackName: string) {
    observer.complete();
    observer.unsubscribe();
    (window as any)[callbackName] = undefined;
  }

  /**
   * Recupera da sessão valores salvos anteriormente pelo nativo ou por qualquer webview.
   * O tempo de vida desses dados dura até o usuário fechar o aplicativo.
   *
   * @typeparam ResponseType Objeto de resposta do SDK Mobile Nativo
   * @param key Chave que foi utilizada para guardar o valor na sessão
   */
  getMemoryItem<ResponseType = any>(key: string): Observable<ResponseType> {
    const callback = new ReplaySubject<any>(1);
    const callbackName = this.generateHash();

    const callbackFun: NativeCallback = (_request, response, error) => {
      try {
        if (error) {
          callback.error(error);
        } else {
          callback.next(response);
          callback.complete();
        }
      } catch (exception) {
        callback.error(exception);
      } finally {
        this.clearCallback(callback, callbackName);
      }
    };

    (window as any)[callbackName] = callbackFun;

    if (this.sdkObject !== undefined) {
      if (this.sdkObject.getMemoryItem.length === 2) {
        this.sdkObject.getMemoryItem(key, callbackName);
      } else {
        const value = this.sdkObject.getMemoryItem(key);
        callback.next(value);
        callback.complete();
      }
    }

    return callback.asObservable();
  }

  /**
   * Consome uma determinanda OP para trafegar dados entre o client e o servidor.
   * É uma abstração do consumo de APIs, porém de forma mais segura utilizando o SDK Mobile Nativo.
   *
   * @typeparam ResponseType Objeto de resposta do SDK Mobile Nativo
   * @param requestData Objeto de configuração para consumir a OP
   */
   routerRequest<ResponseType = any>(requestData: INativeRequest): Observable<ResponseType> {
    const data: INativeRequest = { method: 'POST', ...requestData };
    const callback = new Subject<any>();
    const callbackName = this.generateHash();

    const callbackFunction: NativeCallback = (_request, response, error) => {
      try {
        error ? callback.error(JSON.parse(error || '{}')) : callback.next(JSON.parse(response || '{}'));
      } catch (exception) {
        callback.error(exception);
      } finally {
        this.clearCallback(callback, callbackName);
      }
    };

    (window as any)[callbackName] = callbackFunction;

    this.sdkObject.routerRequest(JSON.stringify(data), callbackName);

    return callback.asObservable();
  }
  

private requestNativeFeature(action: string, category?: string, data?: any, stringifyData: boolean = true, callback?: (response: any) => void) {
  const callbackName = this.generateHash();

  const callbackFunction: (response: any) => void = (response) => {
    console.log("entrou no callback requestNativeFeature");
    console.log(response);
    if (callback) {
      callback(response);
    }
  };

  (window as any)[callbackName] = callbackFunction;

  const json = {
    "action": action,
    "category": category ?? "com.itau.category.DEFAULT",
    "data": stringifyData ? JSON.stringify(data) : data
  };

  this.sdkObject?.requestNativeFeature(JSON.stringify(json), callbackName);
}

itauShop(link: string) {
  this.requestNativeFeature(
    "com.itau.action.NAVIGATION_ITAUSHOP",
    undefined,
    link,
    false
  );
}

pointsCashbackDeeplink(link: string) {
  this.requestNativeFeature(
    "com.itau.action.POINTS_CASHBACK_DEEPLINK",
    "com.itau.category.DEFAULT",
    link,
    false
  );
}
}