import React, { Fragment, useState } from "react";
import { take, map } from "rxjs";

import useEffectOnlyOnce from "../../shared/hooks/useEffectOnlyOnce";
import { useAnalyticsService } from "../../shared/hooks/useAnalyticsService";
import { useExtractService } from "../../shared/hooks/useExtractService";

import { HomeCard } from "../../components/HomeCard/HomeCard";
import Carousel from "../../components/carousel/Carousel";
import CardComponet from "../../components/card/CardComponet";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import ModalComponent from "../../components/modal/ModalComponent";

import { CarouselData } from "../../mock/CarouselData";
import { ModalComponentData } from "../../mock/ModalComponentData";

import { IExtractViewModel } from "./Extract.interface";
import { IHomeCardViewModel } from "../../components/HomeCard/HomeCard.interface";
import { IStatementResponse } from "../../shared/services/Extract/ExtractService.interface";

const Extract = () => {
  const extractService = useExtractService();
  const analyticsService = useAnalyticsService();

  const pageName = "IUPPTelaExtrato";
  const [isTagged, setIsTagged] = useState(false);

  const [showHomeCard, setShowHomeCard] = useState(false);
  const [showErrorCard, setShowErrorCard] = useState(false);
  const [showLoadingCard, setShowLoadingCard] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(true);

  const showError = !showHomeCard && !showModal;
  const showLoading = showLoadingCard || showLoadingModal;

  const [statementsResponse, setStatementsResponse] =
    useState<IStatementResponse>();
  const [balanceResponse, setBalanceResponse] = useState<IHomeCardViewModel>();

  function getStatements() {
    setShowErrorModal(false);
    setShowLoadingModal(true);

    const statementsResponseSubscription = extractService
      .getStatements()
      .pipe(take(1))
      .subscribe({
        next: (statementsResponse) => {
          setStatementsResponse(statementsResponse);
          setShowModal(true);
          setShowLoadingModal(false);
        },
        error: () => {
          setShowErrorModal(true);
          setShowLoadingModal(false);
        },
      });

    return () => {
      statementsResponseSubscription.unsubscribe();
    };
  }

  function getBalance() {
    setShowErrorCard(false);
    setShowLoadingCard(true);

    const balanceResponseSubscription = extractService
      .getBalance()
      .pipe(
        take(1),
        map((balanceResponse) => {
          return {
            valueInPoints: balanceResponse.score,
            valueInCash: balanceResponse.score_in_cash,
          } as IHomeCardViewModel;
        })
      )
      .subscribe({
        next: (props) => {
          setBalanceResponse(props);
          setShowHomeCard(true);
          setShowLoadingCard(false);
        },
        error: () => {
          setShowErrorCard(true);
          setShowLoadingCard(false);
        },
      });

    return () => {
      balanceResponseSubscription.unsubscribe();
    };
  }

  function fetchData() {
    getStatements();
    getBalance();
  }

  useEffectOnlyOnce(
    () => {
      analyticsService.trackState(pageName);
      fetchData();
    },
    [isTagged],
    (dependencies: boolean[]) => dependencies[0] === false
  );

  const viewModel: IExtractViewModel = {
    carousel: CarouselData,
    modalComponent: ModalComponentData,
  };

  return (
    <Fragment>
      <Carousel data={viewModel.carousel} />
      <CardComponet />

      {showError && !showLoading && (
        <Fragment>
          <div className="mt-5 mb-5">
            <ErrorMessage
              title="algo deu errado :("
              subtitle="Não foi possível carregar as informações dos seus pontos e extrato"
              icons="icon-itaufonts_exclamacao"
              retry={fetchData}
            />
          </div>
        </Fragment>
      )}

      {(showHomeCard || showErrorCard) && !showError && (
        <Fragment>
          <HomeCard retryHandler={getBalance} infosHomeCard={balanceResponse} />
        </Fragment>
      )}

      {showLoading && <span className="loading"></span>}

      {(showModal || showErrorModal) && !showError && (
        <Fragment>
          <ModalComponent
            retryHandler={getStatements}
            statements={statementsResponse}
            viewModel={viewModel.modalComponent}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Extract;
