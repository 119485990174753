import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import LogoMarketPlace from "../../Styles/img/icons/icon-marketplace.png"

const MarketPlaceCars = () => {
  // TODO: Carregar tagueamento

  function adobeTrackStateMarketPlaceCars() {
    const analyticsObj = {
      "page": {
          "name": "MarketPlaceCars",
          "statusLogin": "Logado"
      },
      "visitor": {
          // "segmentoConta": native.getMemoryItem('segmento')
      },
      "rule": "pageLoad"
    };
    console.log(analyticsObj)
  }

  useEffect(() => {
    adobeTrackStateMarketPlaceCars()
  }, []);

  let navigate = useNavigate();

  const handleContestExtract = () => {
    navigate('/contest-extract')
  }

  const handleToHomeMarket = () => {
    navigate('/')
  }

  return (
    <div className="m-app">
      <Row>
        <Col xs={12} className="my-5 header-internal" onClick={handleToHomeMarket}>
          <div className="col-1 txt-left">
            <i className="icon-itaufonts_seta color-ui-new-1 font-size-24" />
          </div>
        </Col>
        <Col tabIndex={0} xs={12} className="mt-4 txt-left">
          <img alt="Logo Iupp" src={LogoMarketPlace} />
        </Col>
        <Col xs={12} className="mt-3 txt-left">
          <h2 tabIndex={0} className="font-size-24 mb-0">
            itaú marketplace
          </h2>
        </Col>
        <Col xs={12} className="mt-0 txt-left">
          <p tabIndex={0} className="font-size-16 color-no-points">11 de fevereiro de 2022 às 13h10</p>
        </Col>
        <Col xs={12} className="mt-5 txt-left">
          <Row>
            <Col tabIndex={0} xs={12} className="row">
              <Col xs={6} className="txt-left">
                <p className="mb-0">valor total</p>
              </Col>
              <Col xs={6} className="txt-right">
                <p className="mb-0">1.550 pts</p>
              </Col>
            </Col>
            <div className="border-bottom-ddd my-3 line-a" />
            <Col tabIndex={0} xs={12} className="row">
              <Col xs={6} className="mt-2 txt-left">
                <p className="mb-0">pontos creditados</p>
              </Col>
              <Col xs={6} className="mt-2 txt-right">
                <p className="color-red-money bold mb-0">- 550 pts</p>
              </Col>
            </Col>
            <div className="border-bottom-ddd my-3 line-b" />
            <Col tabIndex={0} xs={12} className="row">
              <Col xs={6} className="mt-2 txt-left">
                <p className="mb-0">irão expirar em</p>
              </Col>
              <Col xs={6} className="mt-2 txt-right">
                <p className="mb-0">31/06/22</p>
              </Col>
            </Col>
            <div className="border-bottom-ddd my-3 line-c" />
            <Col tabIndex={0} xs={12} className="row">
              <Col xs={6} className="mt-2 txt-left">
                <p className="mb-0">vendido por</p>
              </Col>
              <Col xs={6} className="mt-2 txt-right">
                <p className="mb-0">Azul</p>
              </Col>
            </Col>
            <div className="border-bottom-ddd my-3 line-d" />
            <Col tabIndex={0} xs={12} className="row">
              <Col xs={6} className="mt-2 txt-left">
                <p className="mb-0">categoria</p>
              </Col>
              <Col xs={6} className="mt-2 txt-right">
                <p className="mb-0">passagem aérea</p>
              </Col>
            </Col>
          </Row>
        </Col>
        <div className='mt-5 mb-3'>
          <button className='clean-filters'>
            ir para o pedido
          </button>
          <button className='submit-filters mt-2' onClick={() => handleContestExtract()}>
            contestar extrato
          </button>
        </div>
      </Row>
    </div>
  )
}

export default MarketPlaceCars
