import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import LogoPointsExpired from "../../Styles/img/icons/icon-points-expired.png"

const PointsExpired = () => {
  let navigate = useNavigate();
  const handleWhyPointsExpire = () => {
    navigate('/why-points-expire')
  }

  const handleToHomePointsExpire = () => {
    navigate('/')
  }

  return (
    <div className="m-app">
      <Row>
        <Col xs={12} className="mb-4 mt-5 header-internal" onClick={handleToHomePointsExpire}>
          <div className="col-1 txt-left">
            <i className="icon-itaufonts_seta color-ui-new-1 font-size-24" />
          </div>
        </Col>
        <Col xs={12} className="mt-4 txt-left">
          <img src={LogoPointsExpired} alt="" />
        </Col>
        <Col xs={12} className="mt-1 txt-left">
          <h1>Pontos expirados</h1>
        </Col>
        <Col xs={12} className="mt-1 txt-left">
          <h2>12 de outubro 2021 às 14h02</h2>
        </Col>
        <Col xs={12} className="mt-5 txt-left">
          <Row>
            <Col xs={6} className="txt-left">
              <p>pontos expirados</p>
            </Col>
            <Col xs={6} className="txt-right">
              <p>- 550 pts</p>
            </Col>
            <Col xs={12}><hr /></Col>
            <Col xs={6} className="mt-2 txt-left">
              <p>pontos creditados em</p>
            </Col>
            <Col xs={6} className="mt-2 txt-right">
              <p>24/07/20</p>
            </Col>
            <Col xs={12}><hr /></Col>
            <Col xs={6} className="mt-2 txt-left">
              <p>vendido por</p>
            </Col>
            <Col xs={6} className="mt-2 txt-right">
              <p>Magazine Luiza</p>
            </Col>
            <Col xs={12}><hr /></Col>
            <Col xs={6} className="mt-2 txt-left">
              <p>categoria</p>
            </Col>
            <Col xs={6} className="mt-2 txt-right">
              <p>casa e cozinha</p>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <button className='clean-filters' onClick={() => handleWhyPointsExpire()}>
            por que meus pontos expiram?
          </button>
        </Col>
        <Col xs={12} className="mt-1">
          <button className='submit-filters'>
            ir para o pedido
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default PointsExpired
