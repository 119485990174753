import { EMPTY, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { useNativeCommunicationService } from '../../hooks/useNativeCommunicationService';
import INativeCommunicationService from '../NativeCommunication/NativeCommunicationService.interface';
import IFeatureToggleService from './FeatureToggleService.interface';

export default class FeatureToggleServiceImpl implements IFeatureToggleService {

  /**
   * Lista de features toggles
   * @typeparam  String<string[]>
   * @example '["feat1", "feat2"]'
   */
  private MEMORY_ITEM_FEATURE_TOGGLE = 'feature_toggle';
  private isReady = false;
  private featuresMapping: Map<string, boolean> = new Map();
  features: string[] = [];

  constructor(
    private native: INativeCommunicationService
  ) {
    this.getFeaturesReady()
      .pipe(take(1))
      .subscribe();
  }

  private getFeaturesReady() {
    if (this.native == undefined) {
      return EMPTY;
    }

    return this.native.getMemoryItem(this.MEMORY_ITEM_FEATURE_TOGGLE)
      .pipe(
        tap(features => this.onGetFeatures(features as string)),
        map(_ => this.isReady)
      );
  }

  private onGetFeatures(features: string) {
    if (!features || !features.length) {
      return;
    }

    let list = [];
    try {
      list = JSON.parse(features);
      if (typeof list === 'object' && list.length >= 0) {
        this.isReady = true;
        this.features = list;
      }
    } catch (e) {
      this.features = [];
    }
  }

  private isFeatureEnabledSync(featureKey: string): boolean {
    const featureMapped = this.featuresMapping.get(featureKey);
    if (featureMapped !== undefined) {
      return featureMapped;
    }

    const enabled = Boolean(this.features.find(item => item === featureKey));
    this.featuresMapping.set(featureKey, enabled);
    return enabled;
  }

  isFeatureEnabled(featureKey: string): Observable<boolean> {
    if (this.isReady) {
      return of(this.isFeatureEnabledSync(featureKey));
    }

    return this.getFeaturesReady()
      .pipe(
        map(_ready => this.isFeatureEnabledSync(featureKey)),
      );
  }
}
