import React, { FC } from "react";
import { useNativeCommunicationService } from "../../hooks/useNativeCommunicationService";
import { useSecurityService } from "../../hooks/useSecurityService";
import Contextualizer from "../Contextualizer";
import ProvidedServices from "../ProvidedServices";
import AnalyticsServiceImpl from "./AnalyticsService";
import { IAnalyticsService } from "./AnalyticsService.interface";

const AnalyticsServiceContext = Contextualizer.createContext(ProvidedServices.AnalyticsService);

const AnalyticsService: FC<any> = ({children}: any) => {
  const analyticsService: IAnalyticsService = new AnalyticsServiceImpl(
    useSecurityService(),
    useNativeCommunicationService()
  );

  return (
    <>
      <AnalyticsServiceContext.Provider value={analyticsService}>
        {children}
      </AnalyticsServiceContext.Provider>
    </>
  )
}

export default AnalyticsService;
