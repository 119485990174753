import { EMPTY, Observable, from, forkJoin } from "rxjs";
import { mergeMap, map, take, bufferCount } from 'rxjs/operators';
import { useNativeCommunicationService } from "../../hooks/useNativeCommunicationService";
import { INativeRouterItem } from "../NativeCommunication/NativeCommunicationService.interface";
import IExtractService, { IBalanceResponse, IStatementExpiredResponse, IStatementResponse } from "./ExtractService.interface";

export default class ExtractServiceImpl implements IExtractService {
  private nativeCommunicationService = useNativeCommunicationService();

  getBalance(): Observable<IBalanceResponse> {
    
    if (this.nativeCommunicationService === undefined) {
      return EMPTY;
    }

    return forkJoin(
      [
        this.nativeCommunicationService.getMemoryItem<string>('cpf')
          .pipe(take(1)),
        this.getHeaders()
      ]
    )
      .pipe(
        take(1),
        mergeMap((values) =>
          this.nativeCommunicationService.routerRequest({
            op: 'benefitsStatementScore',
            method: 'POST',
            header: values[1],
            body: {
              document: values[0]
            }
          })
        )
      );
  }

  getStatements(): Observable<IStatementResponse> {
    if (this.nativeCommunicationService === undefined) {
      return EMPTY;
    }

    let currentDate = new Date();
    let now = new Date();
    let previousDate = new Date(now.setDate(now.getDate() - 365));
    let initialDate = previousDate.toISOString().split('T')[0];
    let finalDate = currentDate.toISOString().split('T')[0];

    return forkJoin(
      [
        this.nativeCommunicationService.getMemoryItem<string>('cpf')
          .pipe(take(1)),
        this.getHeaders()
      ]
    )
      .pipe(
        take(1),
        mergeMap(values => {
          return this.nativeCommunicationService.routerRequest({
            op: 'benefitsStatements',
            method: 'POST',
            header: values[1],
            body: {
              document: values[0],
              initial_date: initialDate,
              final_date: finalDate
            }
          })
        })
      );
  }

  getStatementsPointsExpiration(): Observable<IStatementExpiredResponse> {
    if (this.nativeCommunicationService === undefined) {
      return EMPTY;
    }

    let now = new Date();
    let numberOfDaysInAYear = 365;
    let numberOfYears = 2;
    let dateInTheFuture = new Date(now.setDate(now.getDate() + (numberOfDaysInAYear * numberOfYears)));
    let finalDate = dateInTheFuture.toISOString().split('T')[0];

    return forkJoin(
      [
        this.nativeCommunicationService.getMemoryItem<string>('cpf')
          .pipe(take(1)),
        this.getHeaders()
      ]
    )
      .pipe(
        take(1),
        mergeMap(values => {
          return this.nativeCommunicationService.routerRequest({
            op: 'loyaltyStatementPointsExpiration',
            method: 'POST',
            header: values[1],
            body: {
              document: values[0],
              final_date: finalDate
            }
          })
        })
      );
    
  }

  private getHeaders(): Observable<INativeRouterItem[]> {
    if (this.nativeCommunicationService === undefined) {
      return EMPTY;
    }

    let memoryItemKeys = ['apiKey', 'correlationID', 'flowId'];
    let headerKeys = ['x-itau-apikey', 'x-itau-correlationID', 'x-itau-flowID'];

    return from(memoryItemKeys)
      .pipe(
        take(memoryItemKeys.length),
        mergeMap(memoryItemKey => this.nativeCommunicationService.getMemoryItem<string>(memoryItemKey)),
        bufferCount(memoryItemKeys.length),
        take(1),
        map(memoryItemValues => memoryItemValues.map((memoryItemValue, index) => {
          return {
            key: headerKeys[index],
            value: memoryItemValue
          } as INativeRouterItem
        }))
      )
  }
}
