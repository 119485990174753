import img1 from "../Styles/img/notPoints.png"
import { ICarouselItem } from "../components/carousel/Carousel.interface"

export const MockBannerNotPoints: ICarouselItem[] = [
    {
        "icon": "icon_warning icon-slider-size",
        "title": "Você ainda não possui pontos!",
        "description": "Aproveite nossas ofertas e ganhe 1 ponto a cada 1 real gasto no Marketplace itaú",
        "urlImg": img1,
        "buttonTxt": "começa a ganhar pontos",
        "eventLabel": "ComecarGanharPontos",
        "URL": "https://www.iupp.com.br/redirect/cash?utm_source=canal_proprietario&utm_medium=mobile&utm_campaign=iupp-vendas-shopping-interno&utm_content=visitantes-warning-shop_iupp-shop"
    }
];
