import React from "react";
import "./estilo.css";
import Button from "react-bootstrap/Button";
import { useAnalyticsService } from "../../shared/hooks/useAnalyticsService";
import HTMLReactParser from "html-react-parser";

const CardImg = (props: CardImageViewListModel) => {
  const analyticsService = useAnalyticsService();
  const analyticsPageName = 'IUPPTelaExtratoNuncaPontuou';

  function onCardTapped(card: CardImageItem) {
    let eventLabel = analyticsService.captalizeAndRemoveSpaces(card.title);
    let category = 'Objeto Visto';
    let action = 'Extrato';
    analyticsService.trackAction(analyticsPageName, eventLabel, action, category);
    window.open(card.url);
  }

  return (
    <div>
      {props.mock.map((card, index) => {
        const infoClassName = card.info == undefined ? 'CardInfo none' : 'CardInfo';

        return (
          <div tabIndex={0} key={index} className={`imgCard ${card.urlImg}`}>
            <div className="info">
              <h3 className="CardTitle">{card.title}</h3>
              <p className={infoClassName}>{card.info}</p>
              <div className="d-flex-all-center">
                <div className="col-7">
                  <span>
                   { HTMLReactParser(card.description) }
                  </span>
                </div>
                <div className="col">
                  <Button
                    variant="outline-primary"
                    aria-label={card.buttonTxt}
                    onClick={() => onCardTapped(card) }
                    >
                      {card.buttonTxt}
                    </Button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface CardImageViewListModel {
  mock: CardImageItem[];
}

interface CardImageItem {
  title: string;
  description: string;
  info?: string;
  urlImg: string;
  buttonTxt: string;
  url: string;
}

export default CardImg;
