import React, { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import Modal from "react-bootstrap/Modal";
import { ModalExpiredComponentData } from "../../mock/ModalExpiredComponentData";
import { useNavigate } from "react-router-dom";
import { IContentTabsExpiredProps, IStatementDateItem, IStatementDetailItem } from "./ContentTabs.interface";

const ContentTabsExpired = (props: IContentTabsExpiredProps) => {
  const [showCountTags, setShowCountTags] = useState(false);
  const [countSelectFilterTags, setCountSelectFilterTags] = useState(0);

  const [show, setShow] = useState(false);
  const [filterDate, setFilterDate] = useState("");

  const [semResultado, setSemResultado] = useState(false);
  const [buttonFilter, setButtonFilterActive] = useState(false);
  const [clearAllDisabled, setClearAllDisabled] = useState(true);
  const [isFilterSelect, setIsFilterSelect] = useState(false);
  
  const [buttonActiveDate, setButtonActiveDate] = useState(false);

  const [clearButtonDate, setClearButtonDate] = useState(false);
  const [clearButtonType, setClearButtonType] = useState(false);
  const [clearButtonOrder, setClearButtonOrder] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [targetNumberFilterDate, setTargetNumberFilterDate] = useState(0);

  // TODO: preencher dados com RouterRequest
  // TODO: handleNavegateContent leva para interna
  let navigate = useNavigate();
  const statementsViewModel = props.statementsExpiredProp.map(statement => {
    const accumulator = (acc: number, current: number) => acc + current;

    return {
      date: statement.date,
      points: statement?.details?.map(detail => detail.amount)
        .reduce(accumulator),
      details: statement?.details?.map(detail => {
        return {
          icons: 'icon-itaufonts_full_compras',
          product: detail.description,
          valueInPoints: detail.amount,
        } as IStatementDetailItem
      })
    } as IStatementDateItem
  });

  const [statementsViewModelState, setStatementsViewModelState] = useState(statementsViewModel);

  const handleNavegateContentKeyEnter = (e: any) => {
    if(e.key === 'Enter'){
      navigate("/");
    }
  };

  const viewModel = {
    modalComponent: ModalExpiredComponentData
  }

  const buttonsNoActive = () => (!buttonActiveDate && setClearAllDisabled(true), setIsFilterSelect(false))

  function openModal() {
    buttonsNoActive();
    setShow(true);
  }

  function cleanFilterDate () {
    setClearButtonDate(true);
    setButtonActiveDate(false);
    setFilterDate("");
    setCountSelectFilterTags(countSelectFilterTags-1);
    setTargetNumberFilterDate(0);
  }

  function cleanAllFilters() {
    setIsFilterSelect(false);
    cleanFilterDate();
    setCountSelectFilterTags(0);
    setClearAllDisabled(true);
  }

  function handleFilterDate (e: any) {
    let target = e.target.innerHTML;
    let targetNumber = parseInt(target.replace(/ dias/g,''));
    !buttonActiveDate && setCountSelectFilterTags(countSelectFilterTags + 1);
    setTargetNumberFilterDate(targetNumber);    
    setFilterDate(target);
    setButtonActiveDate(true);
    setIsFilterSelect(true);
    setClearAllDisabled(false);
    setSemResultado(false);
  }

  function handleFiltrar() {
    setButtonFilterActive(true);
  }

  const dateActiveAndTypeInactive = buttonActiveDate;

  function filtrar(rangeFilter: number) {

    let filteredData = statementsViewModel.filter((data) => {
      const now = new Date();
      const range = now.setDate(now.getDate() + rangeFilter);
      const date = new Date(data.date).getTime();

      if(dateActiveAndTypeInactive) {
        return date <= range;
      }
      
      return true;

    }).map(item => {
      let filteredDetails = item.details;

      return {
        ...item,
        details: filteredDetails
      } 
    })

    if ( filteredData.length === 0 ) {
      setSemResultado(true);
      setButtonFilterActive(false);
    }

    if( filteredData.length > 0 && buttonFilter) {
      setShowCountTags(true);
      setShow(false);
    }
    
    setStatementsViewModelState(filteredData)
    setButtonFilterActive(false);
    setClearButtonDate(false);
    setClearButtonType(false);
    setClearButtonOrder(false);

    countSelectFilterTags === 0 && setShowCountTags(false);
  }

  useEffect(() => {

    filtrar(targetNumberFilterDate);

  }, [buttonFilter, clearButtonDate, clearButtonType, clearButtonOrder])

  return (
    <Fragment>
      <div data-testid="content-tabs" className="d-flex-start-center my-2" style={{overflow: "overlay", height: "60px"}}>
        <button data-testid="filter-button" className="left color-blue" onClick={openModal}>
          <i
            aria-label="Filtrar"
            className="icon icon-itaufonts_filtro ms-2 me-2"
          />
          { !showCountTags && "Filtrar" }
        </button>
        
        { showCountTags && <button className="filter-counter right">{countSelectFilterTags}</button> }
        
        { buttonActiveDate &&
          <button
            role="listitem"
            className="col-auto button-filter active text-left d-flex justify-content-between align-items-center p-2 me-2 pb-1 pt-2 filter-in-page"
            onClick={() => cleanFilterDate()}
          >
            { targetNumberFilterDate } dias
            <i
              aria-label="Remover Filtro"
              className="icon icon_close ms-2 font-size-10"
            />
          </button>
        }

      </div>
      { statementsViewModelState.map((statement, i) => {
          return (
            <div key={i}>
              <ul className="list-tabs mx-1">
                <li>
                  <div className="row mx-2 border-bottom-ddd">
                    <div role="text" className="col-12 mt-2 d-flex">
                      <p className="col-8 txt-left">
                        {"a expirar em "}
                        <Moment locale="pt-br" format="DD MMM YYYY" withTitle>
                          {statement.date}
                        </Moment>
                      </p>
                      <p aria-label={`${statement.points.toLocaleString("pt-BR")} pontos`} className="col-4 txt-right color-red-money">
                        {`${statement.points.toLocaleString("pt-BR")} pts`}
                      </p>
                    </div>
                  </div>
                  {statement.details.map((valor, index) => {
                    return (
                      <div key={index} className="col-12 my-4 d-flex align-items-center row mx-2 btn-list">
                        <div
                          className={`col-2 icon-with-rounded-background w-32 ${
                            valor.valueInPoints > 0
                              ? "red-background"
                              : "gray-background"
                          }`}
                        >
                          <i
                            className={`icon ${valor.icons} ${
                              valor.valueInPoints > 0
                                ? "color-red-money"
                                : "color-ui-new-1"
                            }`}
                          ></i>
                        </div>
                        <div className="col txt-left mx-4">
                          <p className="my-0">{valor.product}</p>
                          <p className="color-ui-new-1 my-0 font-size-14">
                            {valor.vendorName}
                          </p>
                        </div>
                        <div className="col txt-right">
                          <p
                            aria-label={`${valor.valueInPoints.toLocaleString()} pontos`}
                            className={`my-0 color-red-money`}
                          >
                            {valor.valueInPoints.toLocaleString("pt-BR")} pts
                          </p>
                        </div>
                        <div
                          role="link"
                          aria-label="Ir página de extrato"
                          className="col-1 txt-right d-flex-all-center d-none"
                          onKeyUp={(e) => handleNavegateContentKeyEnter(e)}
                        >
                          <i className="icon icon_right color-ui-new-1 font-size-12"></i>
                        </div>
                      </div>
                    );
                  })}
                </li>
              </ul>
            </div>
          );
        })}

      <Modal show={show} onHide={() => setShow(false)} data-testid="filter-modal">
        <Fragment>
          <div className="modal-body d-flex justify-content-between">
            <div className="mt-2">
              <h2>{"Filtrar"}</h2>
              { semResultado && <p className="color-red-money"><b>0</b> resultados para os filtros selecionados</p> }
            </div>
            <button onClick={() => {setShow(false)}} type="button" className="btn-close" aria-label="Fechar"></button>
          </div>
        </Fragment>
        <Modal.Body>
          <Fragment>
            <div role="text" className="d-flex-all-center">
              <h2 className="col title-points">a expirar nos próximos</h2>
            </div>
            <div className="d-flex flex-wrap">
              {viewModel.modalComponent.days.map((date, i) => {
                return (
                  <div
                    data-testid={`optionFilterDate`+i}
                    role="option"
                    id={i.toString()}
                    key={i}
                    className={`p-2 button-filter ${filterDate === date.name ? "active" : ""}`}
                    onClick={(e) => {handleFilterDate(e)}}
                    aria-selected={filterDate === date.name ? true : false}
                  >
                    {date.name}
                  </div>
                )
              })}
            </div>

            <div className="d-flex flex-wrap mt-4">

              <button
                className={'col clean-filters me-3'}
                onClick={cleanAllFilters}
                data-testid='btn-submit'
                disabled={clearAllDisabled}
              >
                limpar filtro
              </button>

              { semResultado === true ?
                <button
                  className={isLoading ? 'submit-filters hide-txt' : 'submit-filters col'}
                  disabled={true}
                >
                  sem resultado
                </button>
              : 
                <button
                  className={isLoading ? 'submit-filters hide-txt' : 'submit-filters col'}
                  onClick={() => {handleFiltrar()}}
                  data-testid='btn-submit'
                  disabled={!isFilterSelect}
                >
                  filtrar
                </button> 
              }
              { isLoading && <span className="loading"></span> }
            </div>
          </Fragment>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ContentTabsExpired;