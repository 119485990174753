import { BehaviorSubject, Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { useNativeCommunicationService } from '../../hooks/useNativeCommunicationService';
import { useSecurityService } from '../../hooks/useSecurityService';
import INativeCommunicationService from '../NativeCommunication/NativeCommunicationService.interface';
import ISecurityService from '../Security/SecurityService.interface';
import { IAnalyticsService } from './AnalyticsService.interface';

export default class AnalyticsServiceImpl implements IAnalyticsService {
  private SQUAD_RESPONSAVEL = 'ComunidadeLoyalty:SquadJornadasDoParticipante';
  private PAGENAME_ITAUCARD_PREFIX = 'IC:LG:NCC:';
  private PAGENAME_SUPERAPP_PREFIX = 'SA:LG:PF:';
  private PAGENAME_PREFIX = 'Cartoes:ProgramaDePontos:';
  private DEFAULT_CATEGORY = 'Clique';
  private DEFAULT_ACTION = 'Extrato';
  private EVENTLABEL_CONTEXT = ':IUPPTelaExtrato:'
  private EVENTLABEL_SEEN_OBJECT_PREFIX = `ObjetoVisto${this.EVENTLABEL_CONTEXT}`;
  private EVENTLABEL_BUTTON_PREFIX = `BTN${this.EVENTLABEL_CONTEXT}`;

  private segment: Uppercase<string> = "VAREJO";
  private hashedCPF: string = "";
  private bankChannel: string = "SUPERAPP";
  private isTimeout = false;
  private dataLoadedFromMemoryRemain = 2;
  private coreAnalytics;

  constructor(
    private securityService: ISecurityService,
    private nativeService: INativeCommunicationService
  ) {
    this.onInit();
    
    if (environment.production) {
      this.coreAnalytics = (window as any).nativeAnalytics;
    } else {
      this.coreAnalytics = (window as any).nativeAnalytics ?? environment.analyticsObjectMock;
    }
  }

  private isReadyToTagging(): boolean {
    return this.isTimeout || this.dataLoadedFromMemoryRemain == 0;
  }
  
  private onInit() {
    this.securityService.getHashedCPF()
      .pipe(take(1))
      .subscribe(
        hashedCPF => {
          this.hashedCPF = hashedCPF;
          this.dataLoadedFromMemoryRemain -= 1;
        }
      );

    this.nativeService.getMemoryItem('bankChannel')
      .pipe(take(1))
      .subscribe(
        bankChannel => {
          this.bankChannel = bankChannel;
          this.dataLoadedFromMemoryRemain -= 1;
        }
      );

    timer(500)
      .pipe(take(1))
      .subscribe(() => this.isTimeout = true);
  }

  private waitForData(callback: () => void) {
    const initialValue = this.isReadyToTagging();
    let timerSubscription: any | undefined;
    const subject = new BehaviorSubject<boolean>(initialValue);

    subject.subscribe((isReady) => {
      if (isReady) {
        callback();
      }
    });

    if (initialValue) {
      subject.complete();
    } else {
      timerSubscription = timer(100)
        .pipe(
          map(() => this.isReadyToTagging())
        )
        .subscribe((isReadyToTagging) => {
          if (isReadyToTagging) {
            subject.next(true);
            subject.complete();
          }
        });
    }

    

    return () => {
      subject.unsubscribe();
      timerSubscription?.unsubscribe();
    }
  }

  getBankChannel(): string {
    return this.bankChannel;
  }

  trackState(parcialPageName: string) {
    this.waitForData(() => {
      const pageName = this.getPageName(parcialPageName);
      const param = {
        analyticswebview: {
          'rule': 'pageLoad',
          'it_pagename': pageName,
          ...this.getDefaultDimensions()
        },
      };
  
      if (this.coreAnalytics !== undefined) {
        this.coreAnalytics.adobeTrackState(pageName, JSON.stringify(param.analyticswebview));
      }
    })
  }

  trackAction(parcialPageName: string, parcialEventLabel: string, action = this.DEFAULT_ACTION, category = this.DEFAULT_CATEGORY) {
    this.waitForData(() => {
      const pageName = this.getPageName(parcialPageName);
      const eventLabel = this.getEventLabel(parcialEventLabel, category);

      const param = {
        analyticswebview: {
          'rule': 'customLink',
          'it_eventcategory': category,
          'it_eventaction': action,
          'it_eventlabel': eventLabel,
          'it_pagename': pageName,
          ...this.getDefaultDimensions(),
        }
      };

      if (this.coreAnalytics !== undefined) {
        this.coreAnalytics.adobeTrackAction(eventLabel, JSON.stringify(param.analyticswebview));
      }
    });
  }

  private getPageName(parcialPageName: string) {
    const appPrefix = this.bankChannel === 'SUPERAPP' ? this.PAGENAME_SUPERAPP_PREFIX : this.PAGENAME_ITAUCARD_PREFIX;

    return `${appPrefix}${this.PAGENAME_PREFIX}${parcialPageName}`;
  }

  private getEventLabel(parcialEventLabel: string, category: string) {
    if (category === 'Objeto Visto') {
      return `${this.EVENTLABEL_SEEN_OBJECT_PREFIX}${parcialEventLabel}`;
    }

    if (parcialEventLabel.includes(this.EVENTLABEL_CONTEXT)) {
      return parcialEventLabel;
    }

    return `${this.EVENTLABEL_BUTTON_PREFIX}${parcialEventLabel}`;
  }

  private getDefaultDimensions() {
    let params: any = {
      'it_squadresponsaveltag': this.SQUAD_RESPONSAVEL,
      'it_customersegment': this.segment,
    };

    if (this.hashedCPF) {
      params['it_customerid'] = this.hashedCPF;
    }

    return params;
  }

  captalizeAndRemoveSpaces(str: string): string {
    return this.removeAccents(str)
      .replace(/\w\S*/g, txt =>
        txt.charAt(0).toUpperCase() + txt.substring(1)
      )
      .replace(/\s/g, "");
  }

  private removeAccents(originalString: string): string {
    var strAccents: string[] = originalString.split('');
    var strAccentsOut: string[] = new Array<string>();
    const strAccentsLen = strAccents.length;
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
    const accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";

    for (var y = 0; y < strAccentsLen; y++) {
      const currentIndex = accents.indexOf(strAccents[y])
        if (currentIndex != -1) {
          strAccentsOut[y] = accentsOut.substring(currentIndex, currentIndex + 1);
        } else {
          strAccentsOut[y] = strAccents[y];
        }
    }

    return strAccentsOut.join('');
  }
}
