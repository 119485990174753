import React, { Fragment, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./../tabs/estilo.css";
import FilterSection from "../FilterSection/FilterSection";
import IModalComponentParams from "./ModalComponents.interface";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { take } from "rxjs";
import { useExtractService } from "../../shared/hooks/useExtractService";
import { IStatementListByDate, IStatementResponse } from "../../shared/services/Extract/ExtractService.interface";

const ModalComponent = (props: IModalComponentParams) => {
  const [show, setShow] = useState(false);
  const [buttonActiveDate, setButtonActiveDate] = useState(false);
  const [buttonActiveBrand, setButtonActiveBrand] = useState(false);
  const [buttonActiveType, setButtonActiveType] = useState(false);
  const [buttonActivePartener, setButtonActivePartener] = useState(false);

  const [filterDate, setFilterDate] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterBrand, setFilterBrand] = useState("");
  const [filterPartener, setFilterPartener] = useState("");

  const extractService = useExtractService();
  const [statementsViewModel, setStatementsViewModel] = useState<IStatementResponse>();  
  
  
  useEffect(() => {   
    setStatementsViewModel(props?.statements)
  }, [])

  return (
    <Fragment>
      { props?.statements != undefined ?
        <Fragment>
          <div className="sticky-top">
            <div className="d-flex-all-center white-bg py-4">
              <div className="col">
                <h2 arial-label="extrato de pontos" className="title-points m-app">extrato de pontos</h2>
              </div>
            </div>
          </div>

          <FilterSection
            filterDate={filterDate}
            filterType={filterType}
            filterBrand={filterBrand}
            filterPartener={filterPartener}
            statements={props?.statements}
          />
        </Fragment>

        :

        <Fragment>
          <div className="mt-5 mb-5">
            <ErrorMessage 
              title="algo deu errado :(" 
              subtitle="Não foi possível carregar as informações do seu extrato" 
              icons="icon-itaufonts_exclamacao"
              retry={props.retryHandler}
            />
          </div>
        </Fragment>
      }
    </Fragment>
    
  );
};

export default ModalComponent;