import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import LogoPointsReversed from "../../Styles/img/icons/icon-points-reversed.png"

const PointsReversed = () => {
  let navigate = useNavigate();

  const handleToHomePointsReversed = () => {
    navigate('/')
  }

  return (
    <div className="m-app">
      <Row>
        <Col xs={12} className="mb-4 mt-5 header-internal" onClick={handleToHomePointsReversed}>
          <div className="col-1 txt-left">
            <i className="icon-itaufonts_seta color-ui-new-1 font-size-24" />
          </div>
        </Col>
        <Col xs={12} className="mt-4 txt-left">
          <img src={LogoPointsReversed} alt="" />
        </Col>
        <Col xs={12} className="mt-1 txt-left">
          <h1>Pontos extornados</h1>
        </Col>
        <Col xs={12} className="mt-1 txt-left">
          <h2>12 de outubro 2021 às 14h02</h2>
        </Col>
        <Col xs={12} className="mt-5 txt-left">
          <Row>
            <Col xs={6} className="txt-left">
              <p>pontos extornados</p>
            </Col>
            <Col xs={6} className="txt-right">
              <p>+ 1550 pts</p>
            </Col>
            <Col xs={12}><hr /></Col>
            <Col xs={6} className="mt-2 txt-left">
              <p>categoria</p>
            </Col>
            <Col xs={6} className="mt-2 txt-right">
              <p>transferência</p>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <button className='clean-filters'>
            ir para o pedido
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default PointsReversed
