import { IModalComponentViewModel } from "../components/modal/ModalComponents.interface";

export const ModalComponentData: IModalComponentViewModel = {
    days: [
        {
            name: "7 dias"
        },
        {
            name: "15 dias"
        },
        {
            name: "30 dias"
        },
        {
            name: "60 dias"
        },
        {
            name: "90 dias"
        },
        {
            name: "120 dias"
        },
        {
            name: "150 dias"
        },
    ],
    types: [
        {
            name: "todos"
        },
        {
            name: "creditados"
        },
        {
            name: "resgatados"
        },
        {
            name: "expirados"
        }
    ],
    order: [
        {
            name: "mais recente primeiro"
        },
        {
            name: "mais antigo primeiro"
        }
    ]
}
