import React from "react";
import "./Styles/main.css";
import "./Styles/loading.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js"
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Extract from "./view/extract/Extract";
import ExtractDetails from "./view/ExtractDetails/ExtractDetails";
import NotPoints from "./view/notPonits/NotPoints";
import NotScored from "./view/notScored/NotScored";
import WherePointsCome from "./view/WherePointsCome/WherePointsCome";
import InvoiceCredit from "./view/InvoiceCredit/InvoiceCredit"
import MarketPlaceCars from "./view/MarketPlaceCars/MarketPlaceCars"
import MarketPlaceTravelTickets from "./view/MarketPlaceTravelTickets/MarketPlaceTravelTickets"
import Invoice from "./view/Invoice/Invoice"
import InvoiceBlack from "./view/InvoiceBlack/InvoiceBlack"
import PointsExpired from "./view/PointsExpired/PointsExpired"
import PointsReversed from "./view/PointsReversed/PointsReversed"
import WhyPointsExpire from "./view/WhyPointsExpire/WhyPointsExpire"
import ContestExtract from "./view/ContestExtract/ContestExtract"
import NotFound from "./view/NotFound/NotFound"

function App() {
  
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<Extract/>} path="/" />
          <Route element={<ExtractDetails/>} path="extract-details" />
          <Route element={<NotPoints/>} path="points" />
          <Route element={<NotScored/>} path="scored" />
          <Route element={<WherePointsCome/>} path="where-points-come" />
          <Route element={<InvoiceCredit/>} path="invoice-credit" />
          <Route element={<MarketPlaceCars/>} path="marketplace-cars" />
          <Route element={<MarketPlaceTravelTickets/>} path="marketplace-travel-tickets" />
          <Route element={<Invoice/>} path="invoice" />
          <Route element={<InvoiceBlack/>} path="invoice-black" />
          <Route element={<PointsExpired/>} path="points-expired" />
          <Route element={<PointsReversed/>} path="points-reversed" />
          <Route element={<WhyPointsExpire/>} path="why-points-expire" />
          <Route element={<ContestExtract/>} path="contest-extract" />
          <Route element={<NotFound/>} path="*" />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
